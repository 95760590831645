import {ContractorSelect} from "../../selects";
import {Button, Col, Form, Radio, Row} from "antd";
import {AntCurrencyFormatInput} from "../../../inputNumber";
import React from "react";
import {GetAgreementRow, PostPatchAgreementRow} from "../../../../redux/api/agreementRowApiSlice";
import {useGetContractorQuery} from "../../../../redux/api/contractorsApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";


export default function AgreementForm({initialValues, onFinish, canHaveFund}: {
    canHaveFund: boolean,
    initialValues?: Partial<GetAgreementRow>,
    onFinish: (values: PostPatchAgreementRow) => void
}) {

    const [agreementForm] = Form.useForm()
    const watchHasFund = Form.useWatch(['has_fund'], agreementForm)
    const {data: contractor} = useGetContractorQuery(initialValues?.contractor ? {uuid: initialValues.contractor.uuid} : skipToken, {refetchOnMountOrArgChange: true})
    const {data: fund} = useGetContractorQuery(canHaveFund && initialValues?.fund ? {uuid: initialValues.fund.uuid} : skipToken, {refetchOnMountOrArgChange: true})

    return <Form
        layout={'vertical'}
        form={agreementForm}
        onFinish={values => onFinish({
            ...values,
            fund: values.has_fund ? values.fund : null
        })}
        initialValues={{
            has_fund: !!initialValues?.fund?.uuid,
            fund: initialValues?.fund?.uuid,
            contractor: initialValues?.contractor?.uuid,
            quoted_premium: initialValues?.quoted_premium
        }}
    >
        <ContractorSelect
            selectedValue={contractor}
            fieldName="contractor"
            label='Contraente'
            onCreation={({value}) => {
                agreementForm.setFieldValue("contractor", value)
            }}
            rules={[{required: true, message: 'Il contraente è richiesto'}]}
        />
        {canHaveFund && <>
            <Form.Item
                label="È presente una Cassa di Assistenza?"
                name={'has_fund'}
            >
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            {watchHasFund && <ContractorSelect
                selectedValue={fund}
                fieldName="fund"
                label={'Cassa Assistenza'}
                onCreation={({value, label}) => agreementForm.setFieldValue("fund", value)}
                isFund={true}
                rules={[{required: true, message: 'La cassa assistenza è richiesta'}]}
            />}
        </>}
        <Form.Item label="Premio (al 100%)" name={"quoted_premium"} rules={[{
            required: true,
            message: 'Il premio è richiesto',
            validator: (_, value) => {
                if (typeof value !== 'number' || value <= 0) {
                    return Promise.reject();
                }
                return Promise.resolve();
            }

        }]}>
            <AntCurrencyFormatInput/>
        </Form.Item>


        <Row justify={'end'}>
            <Col>
                <Form.Item>
                    <Button type={'primary'} htmlType={'submit'}>Salva</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>
}