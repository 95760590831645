import {Button, message, Modal} from "antd";
import {useCallback, useState} from "react";
import AgreementForm from "./AgreementForm";
import {
    GetAgreementRow, PostPatchAgreementRow,
    usePatchAgreementRowMutation
} from "../../../../redux/api/agreementRowApiSlice";
import {EditOutlined} from "@ant-design/icons";

export default function EditAgreementRow({canHaveFund, agreementRow, negotiationUuid, disabled}: {
    canHaveFund: boolean,
    agreementRow: GetAgreementRow,
    negotiationUuid: string,
    disabled: boolean
}) {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [updateRow, {isLoading}] = usePatchAgreementRowMutation()
    const handleUpdate = useCallback(async (values: PostPatchAgreementRow) => {
        try {
            await updateRow({negotiationUuid, agreementRowUuid: agreementRow.uuid, ...values}).unwrap()
            message.success('Riga aggiornata')
            setIsModalOpen(false)
        } catch {
            message.error("Errore nell'aggiornamento della riga")
        }
    }, [agreementRow.uuid, negotiationUuid, updateRow])

    return <>
        <Modal
            open={isModalOpen}
            destroyOnClose={true}
            onCancel={() => setIsModalOpen(false)}
            footer={false}
        >
            <AgreementForm
                initialValues={agreementRow}
                canHaveFund={canHaveFund}
                onFinish={handleUpdate}/>
        </Modal>
        <Button
            disabled={isLoading || disabled}
            loading={isLoading}
            icon={<EditOutlined/>}
            type={'text'}
            onClick={() => setIsModalOpen(true)}
        />
    </>
}