import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';

import {Col, Row, Card, Form, Input, Radio, message, Select, FormInstance, Spin, Button, Divider, Rate, Tag} from 'antd';

import {Typography} from 'antd';


import {CommonFormComponentProps} from "types/negotiations/components";
import {
  useGetEmissionRatingOptionsQuery,
  useLazyGetAtecoCodesQuery,
  useUpdateContractorMutation
} from "redux/api/contractorsApiSlice";
import {DebounceSelect} from "components/debounceSelect";

import {FileOutlined} from '@ant-design/icons';
import {ContractorDataFormModel, CreateContractorModel} from "types/contractors";
import {debounce} from "lodash";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {useSelector} from "react-redux";
import {ContractorSelect} from "../selects";
import {States} from "../../../types";
import InfoModal from "../../modals/InfoModal";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {NegotiationModel} from "../../../types/negotiations";
import ConnectContractorAndCustomerModal from "../../modals/ConnectContractorAndCustomerModal";
import {useCachedCountriesQuery} from 'redux/api/countriesApiSlice';
import {selectUser} from "../../../redux/features/userSlice";
import {canAlignCustomerContractor} from "../../../utils/permission";
import moment from 'moment';
import 'moment/locale/it'

const {Title} = Typography

export default function Contractor({
                                     forwaredRef,
                                     negotiation,
                                     formInstance,
                                     onFormValuesChange,
                                     disabled,
                                     contractorDataForm,
                                     setSelectedNegotiation,
                                     saveData
                                   }: CommonFormComponentProps & { contractorDataForm: FormInstance<ContractorDataFormModel>, setSelectedNegotiation: Dispatch<SetStateAction<NegotiationModel | undefined>> }) {

  const watchContractor = Form.useWatch(['contractor'], formInstance)
  const isContractorSetCorrectly = !!(watchContractor && watchContractor === negotiation.contractor?.uuid)
  const watchHasFund = Form.useWatch(['has_fund'], formInstance)
  const [fetchAtecos, {isLoading: isAtecosLoading}] = useLazyGetAtecoCodesQuery()
  const [updateContractorRequest, {isLoading: isUpdateContractorLoading}] = useUpdateContractorMutation()
  const {data: emissionRatingData} = useGetEmissionRatingOptionsQuery()
  const [isConnectContractorAndCustomerModalVisible, setIsConnectContractorAndCustomerModalVisible] = useState(false)
  const [isConnectFundAndCustomerModalVisible, setIsConnectFundAndCustomerModalVisible] = useState(false)
  const isLobWithFunds = !!negotiation.lob?.has_funds
  const options = useSelector(selectOptions)
  const isPollingCustomerData = !!(negotiation.contractor?.is_autopilot_pending)
  const isContractorConnectedToCustomer = !!(negotiation.contractor?.customer_uuid)
  const isFundConnectedToCustomer = !!(negotiation.has_fund && negotiation.fund?.customer_uuid)

  const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
  const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []
  const user = useSelector(selectUser)
  const canAlign = canAlignCustomerContractor(user.usertypes.map(el => el.code) || [])

  async function searchAtecos(text: string): Promise<{ label: string, value: string }[]> {
    try {
      const atecosSearchResult = await fetchAtecos({search: text}).unwrap()
      return atecosSearchResult.results.map(ateco => (
          {label: ateco.full_description || ateco.uuid, value: ateco.uuid}
      ))

    } catch (e: any) {
      console.error('Ateco search', e)
      return []
    }
  }

  const updateContractorData = async (data: Partial<CreateContractorModel>, type: 'contractor' | 'fund') => {
    //saveData
    try {
      const watchField = type === 'contractor' ? formInstance.getFieldValue('contractor') : formInstance.getFieldValue('fund')
      if (watchField) {
        const updateContractorResult = await updateContractorRequest({uuid: watchField, data}).unwrap()
        switch (type) {
          case 'contractor':
            setSelectedNegotiation(prevNegotiation => prevNegotiation ? ({
              ...prevNegotiation,
              contractor: updateContractorResult
            }) as NegotiationModel : prevNegotiation)
            break;
          case 'fund':
            setSelectedNegotiation(prevNegotiation => prevNegotiation ? ({
              ...prevNegotiation,
              fund: updateContractorResult
            }) as NegotiationModel : prevNegotiation)
            break;
        }
        message.success('Dati aggiornati')
      } else {
        message.error(type=== 'contractor' ? 'Contraente non impostato' : 'Cassa assistenza non impostata')
      }
    } catch (e: any) {
        console.error('debounce', e)
        message.error('Impossibile aggiornare i dati')
      }

  }
  const debounceUpdateContractor = useMemo(
      () => debounce((data: Partial<CreateContractorModel>) => updateContractorData(data, 'contractor'), 1500)
      , [updateContractorData]);

  const debounceUpdateFund = useMemo(
      () => debounce((data: Partial<CreateContractorModel>) => updateContractorData(data, 'fund'), 1500)
      , [updateContractorData]);

  function onContractorDataChange(changedValues: Partial<ContractorDataFormModel>, values: ContractorDataFormModel) {
    const x: any = {...changedValues}
    if (changedValues?.activity) {
      x.activity = changedValues.activity.value
    }
    const provinceIsChanged = !!x.province
    const countryIsChanged = !!x.country
    // @ts-ignore
    const {province, country} = values

    if (provinceIsChanged) {
      if (province === 'EE' && country === 'IT') {
        x.province = null
        contractorDataForm.setFieldValue('province', null)
      }
      if (province && province !== 'EE' && country !== 'IT') {
        x.country = 'IT'
        contractorDataForm.setFieldValue('country', 'IT')
      }
    }

    if (countryIsChanged) {
      if (country === 'IT' && province === 'EE') {
        x.province = null
        contractorDataForm.setFieldValue('province', null)
      }
      if (country && country !== 'IT' && province !== 'EE') {
        x.province = 'EE'
        contractorDataForm.setFieldValue('province', 'EE')
      }
    }

    //due to a bug of ReactNumberFormat + antd, the initial setFields (only here for some reason)
    //triggers a valuesChange event and so an update (despite it shouldn't). this logic to prevent that
    if (!x.hasOwnProperty('yearly_revenues') || x.yearly_revenues !== negotiation.contractor?.yearly_revenues)
      debounceUpdateContractor(x)
  }

  useEffect(() => {
    contractorDataForm.resetFields()
    contractorDataForm.setFieldsValue({
      ...negotiation.contractor,
      activity: (negotiation.contractor?.activity && negotiation.contractor?.activity_full_description ?
          {
            value: negotiation.contractor?.activity,
            label: negotiation.contractor?.activity_full_description
          } : null),
    } as ContractorDataFormModel)

  }, [contractorDataForm, negotiation.contractor])

  return (
      <Spin tip={'Acquisizione dati in corso...'} spinning={isPollingCustomerData}>
        <Card ref={forwaredRef} id="contractor" bordered={false} style={{
          // padding: '24px',
          borderRadius: '12px',
          width: '100%'
        }}>
          <Row>
            <Col>
              <Title level={3}>
                <FileOutlined/>
              </Title>
            </Col>
            <Col style={{marginLeft: '12px'}}>
              <Title level={3}>Dati contraente</Title>
            </Col>
          </Row>
          <Form
              disabled={disabled}
              form={formInstance}
              name='contractor'
              layout="vertical"
              requiredMark={false}
              onValuesChange={onFormValuesChange}
              style={{marginTop: '24px'}}
          >
            <Row justify={'space-between'}  style={{marginTop: '1.5rem'}}>
              <Col xs={24} md={18}>
                <ContractorSelect
                    fieldName="contractor"
                    label={<>
                      Contraente
                      {!!watchHasFund && <InfoModal title={''}
                                                    description={"Se è presente una Cassa di Assistenza il contraente identificato è l'Associato."}/>}
                    </>}
                    disabled={negotiation.state !== States.Rore}
                    onCreation={({value, label}) => {
                      saveData({contractor: value})
                    }}
                    selectedValue={negotiation.contractor || undefined}
                />
              </Col>
              <Col xs={24} md={4} style={{display: "flex", flexDirection: "column"}}>
                <span style={{marginBottom: "8px"}}>Rating NZIA:</span>
                {negotiation.contractor?.emissions_rating ?
                    <div>
                      <Tag color={negotiation.contractor?.emissions_rating === "1" ? "green" : negotiation.contractor?.emissions_rating === "2" ? "orange" : "red"}>
                        {emissionRatingData?.find(el => el.value === negotiation.contractor?.emissions_rating)?.text}
                      </Tag>
                    </div>
                    : "-"}
              </Col>
            </Row>

          </Form>
          <Form
              <ContractorDataFormModel>
              form={contractorDataForm}
              disabled={disabled || !isContractorSetCorrectly}
              name='contractorData'
              layout="vertical"
              requiredMark={false}
              onValuesChange={onContractorDataChange}
          >
            <Row justify={'space-between'} align={'middle'} style={{marginTop: '1.5rem'}}>
              <Col xs={24} md={11}>
                <Form.Item label={"Indirizzo"} name={"address"}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label={"Civico"} name={"street_number"}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label={"Città"} name={"city"}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label={"Cap"} name={"postcode"}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label={"Nazione"} name={'country'}>
                  <Select
                      optionFilterProp={'label'}
                      showSearch
                      options={countries}
                      loading={isLoadingCountries}
                      virtual={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label="Provincia" name='province'>
                  <Select
                      disabled={negotiation.contractor?.country !== 'IT'}
                      virtual={false}
                      showSearch
                      placeholder={disabled ? "" : "Seleziona una provincia"}
                      filterOption={(input, option) => {
                        return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
                      }}
                      options={options.provinces.map(province => ({
                        key: province.value,
                        value: province.value,
                        label: province.text
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form
              <ContractorDataFormModel>
              form={contractorDataForm}
              disabled={disabled || !isContractorSetCorrectly}
              name='contractorData'
              layout="vertical"
              requiredMark={false}
              onValuesChange={onContractorDataChange}
          >
            <Row align='middle' justify='space-between'>
              <Col xs={24} md={11}>
                <Form.Item label="Fatturato annuo" name='yearly_revenues'>
                  <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                </Form.Item>
              </Col>
              <Col xs={24} md={11}>
                <Form.Item label="Partita IVA" name={'vat_number'}>
                  <Input maxLength={16} disabled={!!negotiation.contractor?.customer_uuid}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Descrizione attività" name={'activity'}>
              <DebounceSelect
                  virtual={false}
                  showSearch
                  placeholder={disabled ? "" : "Comincia a digitare per cercare un attività.. (nome o ateco) "}
                  fetchOptions={searchAtecos}
                  style={{width: '100%'}}
              />
            </Form.Item>
            <Form.Item label={"Ente Pubblico / Società Partecipata Pubblica"} name={"is_public"}>
              <Radio.Group>
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          {canAlign && !isContractorConnectedToCustomer && !!negotiation.contractor && <>
              <ConnectContractorAndCustomerModal
                  isFund={false}
                  isVisible={isConnectContractorAndCustomerModalVisible}
                  setIsVisible={setIsConnectContractorAndCustomerModalVisible}
                  contractor={negotiation.contractor}
                  updateContractor={(c) => {
                    setSelectedNegotiation(prevState => {
                      if (prevState) {
                        return {...prevState, contractor: c}
                      } else {
                        return prevState
                      }
                    })
                  }}
              />
              <Button
                  style={{padding: 0}}
                  type={'link'}
                  onClick={() => setIsConnectContractorAndCustomerModalVisible(true)}
              >
                  Carica i dati del contraente...
              </Button>
          </>}

          <Form
              hidden={!isLobWithFunds}
              disabled={disabled}
              form={formInstance}
              name='hasFunds'
              layout="vertical"
              requiredMark={false}
              onValuesChange={onFormValuesChange}
              style={{marginTop: '24px'}}
          >
            <Row align='middle' justify='space-between'>
              <Col xs={24}>
                <Form.Item label="È presente una Cassa di Assistenza?" name={'has_fund'}>
                  <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {watchHasFund && <><Col xs={24} md={11}>
                  <ContractorSelect
                      fieldName="fund"
                      label={'Cassa Assistenza'}
                      onCreation={({value, label}) => saveData({fund: value})}
                      selectedValue={negotiation.fund || undefined}
                      isFund={true}
                  />
              </Col>
                  <Col xs={24} md={11}>
                      <Form.Item label="Partita IVA">
                          <Input key={negotiation.fund?.uuid || 'not_set'} maxLength={16} disabled={!!negotiation.fund?.customer_uuid} defaultValue={negotiation.fund?.vat_number || ""} onChange={e => debounceUpdateFund({vat_number: e.target.value || ""})}/>
                      </Form.Item>
                  </Col></>}
            </Row>
          </Form>
          {canAlign && !isFundConnectedToCustomer && !!negotiation.fund && <>
              <ConnectContractorAndCustomerModal
                  isFund={true}
                  isVisible={isConnectFundAndCustomerModalVisible}
                  setIsVisible={setIsConnectFundAndCustomerModalVisible}
                  contractor={negotiation.fund}
                  updateContractor={(f) => {
                    setSelectedNegotiation(prevState => {
                      if (prevState) {
                        return {...prevState, fund: f}
                      } else {
                        return prevState
                      }
                    })
                  }}
              />
              <Button
                  style={{padding: 0}}
                  type={'link'}
                  onClick={() => setIsConnectFundAndCustomerModalVisible(true)}
              >
                  Carica i dati della cassa assistenza...
              </Button>
          </>}
          <Divider/>
          {negotiation.contractor?.bank_reporting_type === "NO" || negotiation.contractor?.bank_reporting_type === "ND" ?
              <>
                <Row align='middle' justify='space-between' gutter={[12, 24]}>
                  <Col xs={24} md={11}>
                    Segnalazione da banca: <br/>
                    <strong>{negotiation.contractor?.bank_reporting_type_label}</strong>
                  </Col>
                  <Col xs={24} md={11}>
                    Sale <br/>
                    <strong>{negotiation.contractor?.sale_label || "-"}</strong>
                  </Col>
                </Row>
              </> :
              <Row align='middle' justify='space-between' gutter={[12, 24]}>
                <Col xs={24} md={11}>
                  Segnalazione da banca: <br/>
                  <strong>{negotiation.contractor?.bank_reporting_type_label}</strong>
                </Col>
                <Col xs={24}>
                  Filiale in gestione <br/>
                  <strong>{negotiation.contractor?.bank_branch_labels?.bank_branch || "-"}</strong>
                </Col>
                <Col xs={24} md={11}>
                  Sale <br/>
                  <strong>{negotiation.contractor?.sale_label || "-"}</strong>
                </Col>
                {negotiation.contractor?.bank_reporting_type === "BDT" && <Col xs={24} md={11}>
                  Tipologia <br/>
                  <strong>{negotiation.contractor?.bank_branch_labels?.bank_branch_type || "-"}</strong>
                </Col>}
                <Col xs={24} md={11}>
                  Direzione commerciale <br/>
                  <strong>{negotiation.contractor?.bank_branch_labels?.bank_sales_management || "-"}</strong>
                </Col>
                <Col xs={24} md={11}>
                  Area <br/>
                  <strong>{negotiation.contractor?.bank_branch_labels?.bank_branch_area || "-"}</strong>
                </Col>
              </Row>}
          <Divider/>
          <Row align='middle' justify='space-between' gutter={[12, 24]}>
            <Col xs={24} md={11}>
              Scadenza prevalente programma assicurativo: <br/>
              <strong>{negotiation.contractor?.prevailing_expiring_date ? moment(negotiation.contractor.prevailing_expiring_date).format("D MMM YYYY") : "-"}</strong>
            </Col>
            <Col xs={24} md={11}>
              Qualità rapporto cliente: <br/>
              {negotiation.contractor?.relationship_quality ? <Rate value={negotiation.contractor.relationship_quality} disabled/> : "-"}
            </Col>
          </Row>
        </Card>
      </Spin>
  )
}