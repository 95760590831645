import {Tag} from "antd";
import {CoverageConfirmation} from "../../types/negotiations";

const TAG_COLOR = [
  {code: "0000", color: "blue"},
  {code: "0050", color: "gold"},
  {code: "0100", color: "orange"},
  {code: "0200", color: "green"},
  {code: "0300", color: "red"},
  {code: "0400", color: "red"},
  {code: "0500", color: "purple"},
  {code: "0600", color: "green"},
];

const CoverageConfirmationStatusTag = ({coverageConfirmation}:{coverageConfirmation: CoverageConfirmation}) => {
  return (
    <Tag color={TAG_COLOR.find(tag => tag.code === coverageConfirmation.state)?.color}>
      {coverageConfirmation.state_label}
    </Tag>
  )
};

export default CoverageConfirmationStatusTag;