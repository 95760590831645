import {Col, Row, Table, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {getColumnDateProps, getStandardFilter} from "../../tables/negotiationsTables/columnsUtils";
import dayjs from "dayjs";
import Title from "antd/lib/typography/Title";
import InfoModal from "../../modals/InfoModal";
import React, {useEffect, useState} from "react";
import {cloneDeep, isNil} from "lodash";
import {useLazyGetCoverageConfirmationReportsQuery} from "../../../redux/api/coverageConfirmationApiSlice";
import {CoverageConfirmationReport, CoverageConfirmationReportFilters, PassState} from "../../../types/negotiations";
import {ToNegotiationButton} from "./BelatedEmittedBatchReportCard";

const Info = () => (
    <>
        La tabella mostra l'esito delle operazioni di caricamento dei data model di Pass.
    </>
)

const PAGE_SIZE = 7;
const DEFAULT_COLUMNS: ColumnsType<CoverageConfirmationReport> = [
    {
        title: 'Data di creazione',
        dataIndex: 'date_creation',
        key: 'date_creation',
        ...getColumnDateProps('date_creation'),
        sorter: {
            multiple: 0,
        }, render: (text: string) => {
            return text ? dayjs(text).format('DD-MM-YYYY') : ""
        },
        width: '33%'
    },
    {
        title: 'Esito',
        dataIndex: 'state_display',
        key: 'state_display',
        width: '33%',
        sorter: {
            multiple: 0,
        },
        ...getStandardFilter([
            {text: 'Errore', value: PassState.ERROR},
            {text: 'Completato', value: PassState.OK},
        ]),
        render: (value, record) => {
            switch (record.state_display) {
                case PassState.ERROR:
                    return <span style={{color: 'red'}}>Errore</span>
                case PassState.OK:
                    return "Completato"
            }
        }
    },
    {
        dataIndex: 'negotiation',
        key: 'negotiation',
        width: '33%',
        render: (negotiation, record) => record.negotiation ? <ToNegotiationButton uuid={record.negotiation}/> : ""
    },
    Table.EXPAND_COLUMN
]

export default function UploadPassReportCard({className}: { className: string }) {
    const [getData, {data}] = useLazyGetCoverageConfirmationReportsQuery()
    const [filters, setFilters] = useState<Partial<CoverageConfirmationReportFilters>>({})
    const [sorters, setSorters] = useState<string>("")

    useEffect(() => {
        getData({page: 1, page_size: PAGE_SIZE})
    }, [getData])

    return <div className={className}>
        <Row style={{marginTop: '1rem', marginBottom: '1rem'}} justify={"space-between"} align={'middle'}>
            <Col xs={{offset: 1, flex: 1}} sm={{offset: 0}}>
                <div style={{display: "flex", flexWrap: 'nowrap'}}>
                    <Title level={3}>
                        Esiti caricamento data model di Pass
                    </Title>
                    <InfoModal
                        title={"Esiti caricamento data model di Pass"}
                        description={<Info/>}/>
                </div>
            </Col>
        </Row>
        <Table
            <CoverageConfirmationReport>
            columns={DEFAULT_COLUMNS}
            rowKey={'uuid'}
            scroll={{x: true, scrollToFirstRowOnChange: true}}
            onChange={(pagination, filters, sorters, extra) => {
                const updatedCols = cloneDeep(DEFAULT_COLUMNS)
                const page = pagination.current || 1;
                let requestFilters: Partial<CoverageConfirmationReportFilters> = {}
                let requestSorter: string[] = []

                for (const [key, value] of Object.entries(filters)) {
                    if (!isNil(value)) {
                        switch (key) {
                            case 'date_creation':
                                requestFilters.date_creation_from = value[0] as string
                                requestFilters.date_creation_to = value[1] as string
                                break;
                            case 'state_display':
                                requestFilters[key] = value.join(',')
                                break;
                        }
                    }
                }

                if (Array.isArray(sorters)) {
                    sorters.reverse().forEach((sorter) => {
                        if (!isNil(sorter.order)) {
                            let colToUpdate = updatedCols.find(col => col.key === sorter.columnKey)
                            if (colToUpdate) {
                                if (sorter.order === 'ascend') {
                                    requestSorter.push(sorter.field as string)
                                } else {
                                    requestSorter.push('-' + sorter.field)
                                }
                            }
                        }


                    })
                } else {
                    if (sorters.order !== undefined) {
                        if (sorters.order === 'ascend') {
                            requestSorter.push(sorters.field as string)
                        } else {
                            requestSorter.push('-' + sorters.field)
                        }
                    }
                }
                setFilters(requestFilters)
                setSorters(requestSorter.join(','))
                getData({
                    page,
                    page_size: PAGE_SIZE,
                    ordering: requestSorter.join(','), ...requestFilters
                })
            }}
            dataSource={data?.results}
            pagination={{total: data?.count, pageSize: PAGE_SIZE, simple: true}}
            expandable={{
                expandedRowRender: (record) => <>
                    <Typography.Text>Descrizione: </Typography.Text>
                    <Typography.Text italic style={{fontWeight: 'normal'}}>{record.data.error ?? ""}</Typography.Text>
                </>,
                rowExpandable: (record) => record.state_display === PassState.ERROR,
            }}
        />
    </div>
}