import {CommonFormComponentProps} from "../../../../types/negotiations/components";
import {Card, Col, Row} from "antd";
import Title from "antd/lib/typography/Title";
import {ProjectOutlined} from "@ant-design/icons";
import AgreementMaster from "./AgreementMaster";
import AgreementChildren from "./AgreementChildren";

export default function AgreementSection({
                                             forwaredRef,
                                             ...props
                                         }: CommonFormComponentProps) {

    const {negotiation} = props

    return <Card ref={forwaredRef} id="submission" bordered={false} style={{
        // padding: '24px',
        borderRadius: '12px',
        width: '100%'
    }}>
        <Row style={{marginBottom: '24px'}}>
            <Col>
                <Title level={3}>
                    <ProjectOutlined/>
                </Title>
            </Col>
            <Col style={{marginLeft: '12px'}}>
                <Title level={3}>Convenzioni</Title>
            </Col>
        </Row>
        {!negotiation?.master_agreement_negotiation ?
            <AgreementMaster {...props} /> :
            <AgreementChildren negotiationUuid={negotiation.master_agreement_negotiation}/>
        }
    </Card>
}