import {Modal, Button, Row, Col, Space, Checkbox, Divider} from 'antd'
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {selectUser, setTablesPreferences} from "../../redux/features/userSlice";
import {ColumnsType} from "antd/es/table";
import {GeneralNegotiation} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {useSetUserTablesPreferencesMutation} from "../../redux/api/userPreferencesApiSlice";
import {debounce} from "lodash";
import {DELAY_TIMER_CHECKBOX_FILTER, generalColumns} from 'components/tables/negotiationsTables/columnsUtils';


export default function ColumnSelectionGeneralTableFilter({
                                                              currentColumns,
                                                              setCurrentColumns,
                                                              setShowColumnsSelections,
                                                              showColumnsSelection,
                                                              defaultColumns,
                                                          }: {
    currentColumns: ColumnsType<GeneralNegotiation>,
    setCurrentColumns: Dispatch<SetStateAction<ColumnsType<GeneralNegotiation>>>
    setShowColumnsSelections: Dispatch<SetStateAction<boolean>>,
    showColumnsSelection: boolean, defaultColumns: ColumnsType<GeneralNegotiation>,
}) {

    const contractorCols = defaultColumns.filter(col => ['contractor', 'vat_number', 'is_public', 'province', 'atecori', 'underwriter', 'emissions_rating'].includes(col.key as string))
    const commercialDetailCols = defaultColumns.filter(col => ['bank_reporting_type', 'bank_sales_type', 'bank_sales_management', 'bank_branch_region', 'bank_branch_area', 'bank_branch', 'sale', 'relationship_quality', 'prevailing_expiring_date'].includes(col.key as string))
    const reinsurerCols = defaultColumns.filter(col => ['reinsurance_type', 'reinsurers_list', 'reinsurer_broker', 'reinsurer_broker_fee_perc', 'reinsured_share_perc', 'isa_fee',
        'saved_premium', 'attachment_point', 'reinsurance_notes', 'net_premium_to_reinsurers', 'ppw_days'].includes(col.key as string))
    const policyCols = defaultColumns.filter(col => ['is_framework', 'installment_type', 'policy_insured_sum', 'cancellation_terms_days', 'policy_number', 'has_regulation_premium', 'is_auto_renewal', 'is_framework_agreement', 'framework_agreement', 'has_proposal_number'].includes(col.key as string))
    const negotiationDataCols = defaultColumns.filter(col => !reinsurerCols.map(c => c.key).includes(col.key) && !policyCols.map(c => c.key).includes(col.key) && !commercialDetailCols.map(c => c.key).includes(col.key) && !contractorCols.map(c => c.key).includes(col.key))
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const [checkboxValues, setCheckboxValues] = useState<string[]>([])

    useEffect(() => {
        setCheckboxValues(currentColumns.map(el => el.key as string))
    }, [currentColumns])

    const handleCancel = useCallback(() => {
        setShowColumnsSelections(false)
    }, [setShowColumnsSelections])

    const handleChange = useCallback((values: string[]) => {
        const notChangedColumns: string[] = values.filter(id => currentColumns.find(col => col.key === id))
        const newColumns: string[] = values.filter(id => !notChangedColumns.includes(id))
        const removedColumns: string[] = currentColumns.filter(col => !values.includes(col.key as string)).map(col => col.key as string)
        const updatedSorters = user.preferences.tables.general.sorters ? user.preferences.tables.general.sorters?.filter(el => !removedColumns.includes(el.key) && el.type !== undefined) : []
        const updatedFilters = user.preferences.tables.general.filters ? user.preferences.tables.general.filters?.filter(el => !removedColumns.includes(el.key) && el.range.length > 0) : []
        setCurrentColumns(prevState => [...prevState.filter(el => el?.key && notChangedColumns.includes(el.key as string)), ...generalColumns.filter(el => el?.key && newColumns.includes(el.key as string))])
        dispatch(setTablesPreferences({
            general: {
                selected_columns: values
            }
        }))
        updatePreferences({
            general: {
                sorters: updatedSorters,
                filters: updatedFilters,
                selected_columns: [...notChangedColumns, ...newColumns]
            }
        })

    }, [currentColumns, dispatch, setCurrentColumns, updatePreferences, user.preferences.tables.general.filters, user.preferences.tables.general.sorters])

    const debounceChange = useCallback(debounce(values => handleChange(values), DELAY_TIMER_CHECKBOX_FILTER), [])


    return <Modal
        width={'45rem'}
        centered
        bodyStyle={{
            height: '70vh',
            overflowY: 'scroll',
        }}
        title={'Colonne da visualizzare in tabella'}
        onCancel={handleCancel}
        footer={[
            <Row justify={'end'} key={'modal-actions'}>
                <Button
                    danger
                    disabled={currentColumns.length === 1}
                    onClick={() => {
                        setCheckboxValues(['contractor'])
                        handleChange(['contractor'])
                    }}>
                    Reset
                </Button>
            </Row>
        ]}
        open={showColumnsSelection}
    >
        <Checkbox.Group
            value={checkboxValues}
            onChange={(values) => {
                setCheckboxValues(values as string[])
                debounceChange(values)
            }}
        >
            <Space direction={"vertical"} size={'small'} split={<Divider style={{margin: 0, padding: 0}}/>}>
                <p>
                    <b>Spuntare</b> le caselle delle colonne che si vuole visualizzare.<br/>
                    Le colonne selezionate verranno aggiornate <b>automaticamente</b> nella tabella.<br/>
                    Per resettare la selezione corrente, premere il pulsante <b>"Reset"</b> situato in fondo alla finestra.
                </p>
                <div>
                    <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Dati trattativa</div>
                    <Row justify={'space-between'} gutter={[36, 8]}>
                        {negotiationDataCols.map(col => <Col span={11} key={`checkbox-${col.key}`}>
                            <Checkbox value={col.key}>
                                {col.title as string}
                            </Checkbox>
                        </Col>)}
                    </Row>
                </div>

                <div>
                    <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Contraente</div>
                    <Row justify={'space-between'} gutter={[36, 8]}>
                        {contractorCols.map(col => <Col span={11} key={`checkbox-${col.key}`}>
                            <Checkbox value={col.key} {...col.key === 'contractor' && {disabled: true}}>
                                {col.title as string}
                            </Checkbox>
                        </Col>)}
                    </Row>
                </div>

                <div>
                    <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Dettaglio commerciale</div>
                    <Row justify={'space-between'} gutter={[36, 8]}>
                        {commercialDetailCols.map(col => <Col span={11} key={`checkbox-${col.key}`}>
                            <Checkbox value={col.key}>
                                {col.title as string}
                            </Checkbox>
                        </Col>)}
                    </Row>
                </div>

                <div>
                    <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Riass</div>
                    <Row justify={'space-between'} gutter={[36, 8]}>
                        {reinsurerCols.map(col => <Col span={11} key={`checkbox-${col.key}`}>
                            <Checkbox value={col.key}>
                                {col.title as string}
                            </Checkbox>
                        </Col>)}
                    </Row>
                </div>

                <div>
                    <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Polizze</div>
                    <Row justify={'space-between'} gutter={[36, 8]}>
                        {policyCols.map(col => <Col span={11} key={`checkbox-${col.key}`}>
                            <Checkbox value={col.key}>
                                {col.title as string}
                            </Checkbox></Col>)}
                    </Row>
                </div>
            </Space>

        </Checkbox.Group>
        {currentColumns.length === 0 &&
            <p style={{color: 'red', textAlign: 'center'}}>Selezionare almeno una colonna</p>}
    </Modal>
}