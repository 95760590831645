import {Button, message, Modal} from "antd";
import {useCallback, useState} from "react";
import AgreementForm from "./AgreementForm";
import {NegotiationModel} from "../../../../types/negotiations";
import {
    PostPatchAgreementRow,
    useCreateAgreementRowMutation
} from "../../../../redux/api/agreementRowApiSlice";

export default function AddAgreementRow({negotiation, disabled}: { negotiation: NegotiationModel, disabled: boolean }) {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const canHaveFund = !!negotiation.lob?.has_funds
    const [createRow, {isLoading}] = useCreateAgreementRowMutation()

    const handleCreate = useCallback(async ({contractor, fund, quoted_premium}: PostPatchAgreementRow) => {
        try {
            await createRow({
                negotiationUuid: negotiation.uuid,
                contractor,
                fund,
                quoted_premium
            }).unwrap()
            message.success('Riga creata')
            setIsModalOpen(false)
        } catch {
            message.error("Errore nella creazione della riga")
        }
    }, [createRow, negotiation.uuid])

    return <>
        <Modal
            open={isModalOpen}
            destroyOnClose={true}
            onCancel={() => setIsModalOpen(false)}
            footer={false}
        >
            <AgreementForm
                canHaveFund={canHaveFund}
                onFinish={handleCreate}/>
        </Modal>
        <Button
            disabled={isLoading || disabled}
            loading={isLoading}
            onClick={() => setIsModalOpen(true)}>Aggiungi riga</Button>
    </>
}