import {Input} from "antd";
import React from "react";
import {FilterDropdownProps} from "antd/lib/table/interface";
import FilterContainer from "../FilterContainer";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "../columnsUtils";

export default function ReinsuranceNotesFilter(props: FilterDropdownProps) {

    return <FilterContainer {...props}>
        <Input style={{width: '20rem'}}
               placeholder="Filtra per nota..."
               value={props.selectedKeys.length ? props.selectedKeys[0] as string : undefined}
               onChange={(e) => {
                   if (!e.target.value) {
                       props.setSelectedKeys([])
                       props.confirm({closeDropdown: false});
                   } else {
                       props.setSelectedKeys([e.target.value])
                       endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
                   }
               }}
               onPressEnter={() => {
                   if (props.selectedKeys.length) {
                       props.confirm({closeDropdown: false});
                   }
               }}
        />
    </FilterContainer>

}