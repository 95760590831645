import {apiSlice} from "redux/api/baseApiSlice";
import {
    CoverageConfirmation,
    CoverageConfirmationReportFilters,
    CoverageConfirmationReportsResponse,
} from "types/negotiations";
import handleDownloadFile from "../../utils/handleDownloadFile";
import {GetDocumentsModel} from "../../types/documents";

export const coverageConfirmationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => {
        return ({
            getNegotiationCoverageConfirmationSchema: builder.query<{ schema: JSON }, string>({
                query: (coverage_confirmation_uuid) => `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/coverage_confirmation_type/`,
                providesTags: (res, error, uuid) => [
                    {type: 'CoverageConfirmationSchema', id: uuid},
                    'CoverageConfirmation'
                ],
            }),
            getNegotiationCoverageConfirmationData: builder.query<CoverageConfirmation, string>({
                query: (coverage_confirmation_uuid) => `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/`,
                providesTags: (res, error, uuid) => [
                    {type: 'CoverageConfirmationData', id: uuid},
                    'CoverageConfirmation'
                ],
            }),
            createNegotiationCoverageConfirmation: builder.mutation<CoverageConfirmation, string>({
                query: (negotiation_uuid) => ({
                    url: `/negotiation/coverage_confirmation/`,
                    method: 'POST',
                    body: {negotiation: negotiation_uuid}
                }),
                invalidatesTags: ['CoverageConfirmation'],
            }),
            patchCoverageConfirmation: builder.mutation<CoverageConfirmation, {
                uuid: string,
                data?: JSON,
                state?: string
            }>({
                query: ({uuid: coverage_confirmation_uuid, data, state}) => ({
                    url: `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/`,
                    method: 'PATCH',
                    body: {data, state}
                }),
                async onQueryStarted({}, {dispatch, queryFulfilled}) {
                    try {
                        // patch negotiation
                        const {data: response} = await queryFulfilled
                        dispatch(
                            coverageConfirmationApiSlice.util.updateQueryData('getNegotiationCoverageConfirmationData', response.uuid, (draft) => {
                                Object.assign(draft, response)
                            })
                        )
                    } catch {

                    }
                },
            }),
            startPatchFromRore: builder.mutation<CoverageConfirmation, {
                coverageConfirmationUuid: string,
                documentUuid: string,
                coverageConfirmationQuestionCode?: string,
                surveyData: { questionName: string, fileUploadResponse: GetDocumentsModel[] }
            }>({
                query: ({
                            coverageConfirmationUuid: coverage_confirmation_uuid,
                            documentUuid,
                            coverageConfirmationQuestionCode
                        }) => ({
                    url: `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/start_patch_from_rore/`,
                    method: 'PATCH',
                    body: {
                        rore_storage_service_uuid: documentUuid,
                        coverage_confirmation_question_code: coverageConfirmationQuestionCode
                    }
                }),
                async onQueryStarted({coverageConfirmationUuid, surveyData}, {dispatch, queryFulfilled}) {
                    try {
                        const {questionName, fileUploadResponse} = surveyData

                        // start Rore Process
                        const {data: roreResponse} = await queryFulfilled

                        // patch confirmationData
                        await dispatch(
                            coverageConfirmationApiSlice.endpoints.patchCoverageConfirmation.initiate({
                                uuid: roreResponse.uuid,
                                data: {
                                    ...roreResponse.data,
                                    [questionName]: [
                                        ...(roreResponse.data[questionName] ? roreResponse.data[questionName] : []),
                                        ...(fileUploadResponse.filter(el => "uuid" in el) as GetDocumentsModel[])
                                    ]
                                }
                            })
                        )

                    } catch {

                    }
                },
            }),
            confirmSendCoverageConfirmation: builder.mutation<CoverageConfirmation, string>({
                query: (coverage_confirmation_uuid) => ({
                    url: `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/confirm_send/`,
                    method: 'POST',
                    body: {uuid: coverage_confirmation_uuid}
                }),
                invalidatesTags: (res, error, coverageConfirmationUuid) => [{
                    type: 'CoverageConfirmationData',
                    id: coverageConfirmationUuid
                }],
            }),
            confirmNotSendCoverageConfirmation: builder.mutation<CoverageConfirmation, string>({
                query: (coverage_confirmation_uuid) => ({
                    url: `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/confirm_not_send/`,
                    method: 'POST',
                    body: {uuid: coverage_confirmation_uuid}
                }),
                invalidatesTags: (res, error, coverageConfirmationUuid) => [{
                    type: 'CoverageConfirmationData',
                    id: coverageConfirmationUuid
                }],
            }),
            revertDraftCoverageConfirmation: builder.mutation<CoverageConfirmation, string>({
                query: (coverage_confirmation_uuid) => ({
                    url: `/negotiation/coverage_confirmation/${coverage_confirmation_uuid}/revert_draft/`,
                    method: 'POST',
                    body: {uuid: coverage_confirmation_uuid}
                }),
                invalidatesTags: (res, error, coverageConfirmationUuid) => [{
                    type: 'CoverageConfirmationData',
                    id: coverageConfirmationUuid
                }],
            }),
            getLetter: builder.query<Blob, { uuid: string, coverageconfirmation_uuid?: string }>({
                query: ({uuid, coverageconfirmation_uuid}) => {
                    return {
                        url: `/negotiation/negotiations/${uuid}/print_letter/`,
                        params: {coverageconfirmation_uuid},
                        responseHandler: (response) => handleDownloadFile(response),
                        cache: "no-cache",
                    }
                },

            }),
            getCoverageConfirmationDataModel: builder.query<any, { uuid: string }>({
                query: ({uuid}) => {
                    return {
                        url: `negotiation/negotiations/${uuid}/data_model_created/`,
                    }
                },
                providesTags: ['CoverageConfirmation'],
            }),
            getCoverageConfirmationReports: builder.query<CoverageConfirmationReportsResponse, CoverageConfirmationReportFilters>({
                query: (args) => {
                    return {url: `/negotiation/coverage_confirmation_reports/`, params: {...args, is_recent: true}}
                }
            }),
            alignDataNegotiation: builder.mutation<CoverageConfirmation, {coverageConfirmationUuid: string, negotiationUuid: string}>({
                query: ({coverageConfirmationUuid}) => ({
                    url: `/negotiation/coverage_confirmation/${coverageConfirmationUuid}/align_data_negotiation/`,
                    method: 'POST',
                }),
                invalidatesTags: ['NegotiationToAlignWithinCoverageConfirmation'],
            }),
        });
    }
})

export const {
    useGetNegotiationCoverageConfirmationDataQuery,
    useGetNegotiationCoverageConfirmationSchemaQuery,
    useCreateNegotiationCoverageConfirmationMutation,
    usePatchCoverageConfirmationMutation,
    useStartPatchFromRoreMutation,
    useConfirmSendCoverageConfirmationMutation,
    useConfirmNotSendCoverageConfirmationMutation,
    useRevertDraftCoverageConfirmationMutation,
    useLazyGetLetterQuery,
    useGetCoverageConfirmationDataModelQuery,
    useLazyGetCoverageConfirmationReportsQuery,
    useAlignDataNegotiationMutation,
} = coverageConfirmationApiSlice