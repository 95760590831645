import React, { useEffect, useState } from 'react';

import {Col, Row, Card, Form, Select, Alert, Radio} from 'antd';

import { Layout, Typography } from 'antd';



import { CommonFormComponentProps } from "types/negotiations/components";

import { FolderOpenOutlined } from '@ant-design/icons';
import { AntCurrencyFormatInput } from "components/inputNumber";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
const { Title } = Typography
const { Option } = Select


export type ProductBranchType = { [key: (string | number)]: { default: number, disabled: boolean } }
export default function Portfolio({ forwaredRef, formInstance, negotiation, onFormValuesChange, disabled }: CommonFormComponentProps) {

    const [selectedProductBranches, setSelectedProductBranches] = useState<ProductBranchType | undefined>(undefined)
    const watchProductType = Form.useWatch('product_type', formInstance)
    const watchBranches = Form.useWatch('branches', formInstance)

    const user = useSelector(selectUser)


    useEffect(() => {
        if (negotiation && watchProductType) {
            const branches = negotiation.lob.products.find(product => product.product === watchProductType)?.branches
            setSelectedProductBranches(branches)
        }
        else
            setSelectedProductBranches({})
    }, [negotiation, watchProductType])

    const branchesSum = watchBranches && Object.values(watchBranches).filter(value => value).reduce((sum, curr) => sum + curr, 0)


    return (
        <Card ref={forwaredRef} id="portfolio" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>

            <Form
                form={formInstance}
                disabled={disabled}
                name='portfolio'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <Row>
                    <Col>
                        <Title level={3}>
                            <FolderOpenOutlined />
                        </Title>
                    </Col>
                    <Col style={{ marginLeft: '12px' }}>
                        <Title level={3}>Portafoglio</Title>
                    </Col>
                </Row>
                <Form.Item style={{ marginTop: !user.usertypes.find(type => type.code === 'PO') ? '24px' : 0}} label="Prodotto di appartenenza" name={'product_type'} required >
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un prodotto"}
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {negotiation.lob.products.map((product, idx) => {
                            return <Option key={idx} value={product.product}>{product.product} - {product.commercialName}</Option>
                        })}
                    </Select>
                </Form.Item>
                <Row align='middle' justify='space-between'>
                    {selectedProductBranches ? Object.keys(selectedProductBranches).sort().map(branch => {
                        return (

                            <Col key={branch} span={7}>
                                <Form.Item name={['branches', `${branch}`]} label={`Ramo ${branch}`} required
                                    validateStatus={branchesSum && branchesSum > 100 ? 'error' : 'success'}
                                >
                                    <AntCurrencyFormatInput
                                        disabled={selectedProductBranches[branch].disabled}
                                        hasPlaceholder={!disabled}
                                        suffix={' %'} />
                                </Form.Item>
                            </Col>

                        )
                    })
                        : <Title level={5}>Nessun Branch disponibile</Title>
                    }
                </Row>
                {typeof branchesSum === 'number' && branchesSum > 100 && <Alert
                    message="Somma dei rami non valida"
                    description={`La somma delle percentuali di tutti i rami è di ${branchesSum.toFixed(2)}%, eccede di ${(branchesSum - 100).toFixed(2)}% rispetto al valore consentito.`}
                    type='error'
                    style={{ marginTop: '24px', marginBottom: '24px' }}
                />}
                {typeof branchesSum === 'number' && branchesSum < 100 && <Alert
                    message="Somma dei rami non valida"
                    description={`La somma delle percentuali di tutti i rami è di ${branchesSum.toFixed(2)}%, manca di ${(100 - branchesSum).toFixed(2)}% rispetto al valore consentito.`}
                    type='error'
                    style={{ marginTop: '24px', marginBottom: '24px' }}
                />}
            </Form>
        </Card >

    )
}