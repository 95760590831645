import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Form, message, Row} from 'antd';
import {
    CheckCircleOutlined,
    FileOutlined,
    FolderOpenOutlined,
    MailOutlined,
    ProfileOutlined, ProjectOutlined,
    RightOutlined,
    SolutionOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';
import debounce from 'lodash.debounce'
import _, {cloneDeep} from 'lodash'
import {NegotiationDetailMenu} from "components/negotiationForm/sidebars/menu";
import Submission from "components/negotiationForm/form/submission";
import Contractor from "components/negotiationForm/form/contractor";
import Reinsurance from "components/negotiationForm/form/reinsurance";
import Negotiation from "components/negotiationForm/form/negotiation";
import Portfolio from "components/negotiationForm/form/portfolio";
import PoliciesAndIssues from "components/negotiationForm/form/policiesAndIssues";
import PortfolioPoliciesAndIssues from "components/negotiationForm/form/portfolioView/policiesAndIssues";
import {NegotiationDetailSubForm} from "types/negotiations/components";
import {
    CalculatedField,
    FieldsCalculated,
    NegotiationFormModel,
    UpdateNegotiationRequest
} from "types/negotiations/index";
import {States} from "../../types";
import {NegotiationModel} from "types/negotiations";
import {RiskAppSpinner} from "components/spinners";
import {useSelector} from "react-redux";
import {motion} from 'framer-motion'
import {selectUser} from "redux/features/userSlice";
import {selectOptions} from "redux/features/optionsSlice";
import {useLazyGetNegotiationDetailQuery, useUpdateNegotiationMutation} from "redux/api/negotiationsApiSlice";
import {transformNegotiationFormToUpdateRequest, transformNegotiationToFormFields} from "utils/form/dataParser";
import {FormChangeInfo} from "rc-field-form/es/FormContext";
import {
    calculateContractorSideEffects,
    calculateNegotiationFormSideEffect,
    calculatePoliciesAndIssuesSideEffects,
    calculatePortfolioFormSideEffect,
    calculateReinsuranceFormSideEffects,
    calculateSubmissionFormSideEffects
} from "utils/form/handleFormSideEffects";
import {canModifyForm} from "../../utils/permission";
import {ContractorDataFormModel} from "../../types/contractors";
import NegotiationAlerts from "../../components/negotiationForm/NegotiationAlerts";
import {
    NegotiationDetailStatusManager
} from "../../components/negotiationForm/sidebars/NegotiationDetailsStatusManager";
import AdditionalInsureds from "../../components/negotiationForm/form/additionalInsureds";
import {isDebounceUpdate} from "../../utils/form/debouncedFields";
import AgreementSection from "../../components/negotiationForm/form/agreements/AgreementSection";

export default function NegotiationDetail() {

    const {id: negotiationId} = useParams();
    const navigate = useNavigate();
    const options = useSelector(selectOptions);
    const user = useSelector(selectUser);

    const [form] = Form.useForm<NegotiationFormModel>();
    const [contractorDataForm] = Form.useForm<ContractorDataFormModel>();

    const [
        triggerGetNegotiation,
        {
            isLoading: isLoadingNegotiation,
            isUninitialized,
        }
    ] = useLazyGetNegotiationDetailQuery();
    const [
        triggerUpdateNegotiation,
    ] = useUpdateNegotiationMutation();

    //Scrollspy + scroll on menu item click
    const submissionRef = useRef<HTMLDivElement>(null);
    const additionalInsuredsRef = useRef<HTMLDivElement>(null);
    const contractorRef = useRef<HTMLDivElement>(null);
    const reinsuranceRef = useRef<HTMLDivElement>(null);
    const negotiationRef = useRef<HTMLDivElement>(null);
    const portfolioRef = useRef<HTMLDivElement>(null);
    const policiesAndIssuesRef = useRef<HTMLDivElement>(null);
    const agreementsRef = useRef<HTMLDivElement>(null);

    const [
        isPortfolio,
        setIsPortfolio
    ] = useState<boolean>(false);
    const [
        isFormDisabled,
        setIsFormDisabled
    ] = useState<boolean>(true);
    const [
        selectedNegotiation,
        setSelectedNegotiation
    ] = useState<NegotiationModel | undefined>(undefined);
    const [
        isAsideVisible,
        setIsAsideVisible
    ] = useState(true);
    //both menu and each form are dynamic and render via this state
    const [
        formItems,
        setFormItems
    ] = useState<NegotiationDetailSubForm[]>([]);

    const fetchNegotiation = useCallback(async (id: string) => {
        try {
            const negotiation = await triggerGetNegotiation(id).unwrap()
            setSelectedNegotiation({
                ...negotiation,
                available_state_transitions: negotiation.available_state_transitions.flat()
            })
        } catch (e: any) {
            console.error(`negotiation ${id} fetch`, e)
            if (e?.status === 404) {
                navigate('/')
                message.error('Trattativa non esistente')
            }
        }
    }, [navigate, triggerGetNegotiation]);

    const saveData = useCallback(async (data: Partial<UpdateNegotiationRequest>): Promise<NegotiationModel | undefined> => {
        //saveData
        try {
            if (negotiationId) {
                const updateResult = await triggerUpdateNegotiation({id: negotiationId, data}).unwrap()
                setSelectedNegotiation({
                    ...updateResult,
                    available_state_transitions: updateResult.available_state_transitions.flat()
                })
                message.success('Dati aggiornati')
                return updateResult
            } else
                message.error('Trattativa non impostata')
            return undefined
        } catch (e: any) {
            //in case of update error, rollback to previous negotitation save
            if (selectedNegotiation) {
                const oldFormNegotiation = transformNegotiationToFormFields(selectedNegotiation)
                form.setFieldsValue(oldFormNegotiation)
            }
            console.error('debounce', e)
            message.error('Impossibile aggiornare i dati')
            return undefined
        }
    }, [form, negotiationId, selectedNegotiation, triggerUpdateNegotiation])

    const debounceInputChange = useMemo(
        () => debounce((data: Partial<UpdateNegotiationRequest>) => saveData(data), 1500)
        , [saveData]);

    //From.Provider => not really used
    const onFormChange = (formName: string, info: FormChangeInfo) => {
    }

    //triggered every time a controlled input changes, here is handled the relation between inputs
    const onValuesChange = useCallback((changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) => {
        if (selectedNegotiation && !_.isMatch(transformNegotiationToFormFields(selectedNegotiation), changedValues)) {
            let updatedValues = cloneDeep(values)
            const lob = options.lobs.find(lob => lob.uuid === values.lob)

            updatedValues = calculateSubmissionFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateContractorSideEffects(changedValues, updatedValues)
            updatedValues = calculateReinsuranceFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateNegotiationFormSideEffect(changedValues, updatedValues, lob, options.firstDelegated)
            updatedValues = calculatePortfolioFormSideEffect(selectedNegotiation, changedValues, updatedValues)
            updatedValues = calculatePoliciesAndIssuesSideEffects(changedValues, updatedValues)

            const oldNegotiation = transformNegotiationToFormFields(selectedNegotiation)
            const fieldsToUpdate = _.pickBy(updatedValues, (value, key) => {
                // todo avoid this necessary check (the dates are different) having form field with only the year
                if (key === 'uw_year') {
                    return oldNegotiation.uw_year?.year() !== updatedValues.uw_year?.year()
                } else if (key === 'prorata_uw_year') {
                    return oldNegotiation.prorata_uw_year?.year() !== updatedValues.prorata_uw_year?.year()
                } else {
                    return !_.isEqual(oldNegotiation[key as keyof NegotiationFormModel], value)
                }
            }) as NegotiationFormModel
            form.setFieldsValue(fieldsToUpdate)

            // for resetting values (now only for policy_insured_sum) -> todo: add other fields
            const fieldsToReset = _.pickBy(fieldsToUpdate, (value, key) => value === null && key === 'policy_insured_sum')
            form.resetFields(Object.keys(fieldsToReset))

            const parsedFormValues = transformNegotiationFormToUpdateRequest(fieldsToUpdate)

            let debounceUpdate = isDebounceUpdate(changedValues)

            if (debounceUpdate) {
                debounceInputChange(parsedFormValues)
            } else {
                saveData(parsedFormValues)
            }

        }

    }, [debounceInputChange, form, options.firstDelegated, options.lobs, saveData, selectedNegotiation])

    const setCalculateField = useCallback((field: FieldsCalculated) => {
        if (form.getFieldValue('is_calculated')) {
            const updatedCalculateFields: CalculatedField = {...form.getFieldValue('is_calculated')}
            updatedCalculateFields[field] = !updatedCalculateFields[field]

            onValuesChange({is_calculated: updatedCalculateFields}, form.getFieldsValue())
        }
    }, [form, onValuesChange]);

    const goToDocuments = useCallback((negotiation: NegotiationModel) => {
        navigate(`/documents/${negotiation.uuid}/`, {
            state: {
                status: negotiation?.state,
                policyNumber: negotiation?.policy_number,
                contractor: negotiation?.contractor?.name,
                lob: negotiation?.lob.name,
                underwriter: negotiation?.underwriter?.user_full_name,
                receptionDate: negotiation?.reception_date,
                effectiveDate: negotiation?.policy_effective_date,
                expiringDate: negotiation?.policy_expiring_date,
                uwYear: negotiation?.uw_year,
                broker: negotiation?.broker?.name,
                isia: negotiation?.is_isia
            }
        })
    }, [navigate]);

    useEffect(() => {
        if (user && user.usertypes.length) {
            setIsPortfolio(!!user.usertypes.find(type => type.code === 'PO'))
            setIsFormDisabled(!canModifyForm(user.usertypes.map(el => el.code)))
        }
    }, [user])

    //fetch the request (from query param) negotiation and display it
    useEffect(() => {
        if (negotiationId)
            fetchNegotiation(negotiationId)
    }, [fetchNegotiation, negotiationId])

    //fetch negotiation when customer autopilot is in progress
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (negotiationId && selectedNegotiation?.contractor?.is_autopilot_pending) {
            timer = setInterval(() => fetchNegotiation(negotiationId), 5000)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [fetchNegotiation, negotiationId, selectedNegotiation?.contractor?.is_autopilot_pending])

    const isAdditionalInsuredVisible = !!(selectedNegotiation?.can_manage_certificates)

    useEffect(() => {
        if (selectedNegotiation) {
            const baseProps = {
                formInstance: form,
                saveData: saveData,
                negotiation: selectedNegotiation,
                setNegotiation: setSelectedNegotiation,
                disabled: isFormDisabled,
                setCalculateField,
                onFormValuesChange: onValuesChange
            }
            const baseItems = [
                {
                    uwOrder: 0,
                    portfolioOrder: 6,
                    label: 'Submission',
                    key: 'submission',
                    ref: submissionRef,
                    icon: <MailOutlined/>,
                    component: <Submission {...baseProps} forwaredRef={submissionRef}
                    />
                }, // remember to pass the key prop
                ...isAdditionalInsuredVisible ? [{
                    uwOrder: 2,
                    portfolioOrder: 4,
                    label: 'Assicurati addizionali',
                    key: 'additionalInsureds',
                    ref: additionalInsuredsRef,
                    icon: <UsergroupAddOutlined/>,
                    component: <AdditionalInsureds {...baseProps} forwaredRef={additionalInsuredsRef}/>
                }] : [],
                {
                    uwOrder: 1,
                    portfolioOrder: 3,
                    label: 'Dati contraente',
                    key: 'contractor',
                    ref: contractorRef,
                    icon: <FileOutlined/>,
                    component: <Contractor {...baseProps} forwaredRef={contractorRef}
                                           contractorDataForm={contractorDataForm}
                                           setSelectedNegotiation={setSelectedNegotiation}/>
                }, // which is required
                {
                    uwOrder: 3,
                    portfolioOrder: 5,
                    label: 'Riassicurazione',
                    key: 'reinsurance',
                    ref: reinsuranceRef,
                    icon: <CheckCircleOutlined/>,
                    component: <Reinsurance {...baseProps} forwaredRef={reinsuranceRef}/>
                },
                {
                    uwOrder: 4,
                    portfolioOrder: 7,
                    label: 'Dati trattativa',
                    key: 'negotiation',
                    ref: negotiationRef,
                    icon: <ProfileOutlined/>,
                    component: <Negotiation {...baseProps} forwaredRef={negotiationRef}/>
                },
                {
                    uwOrder: 5,
                    portfolioOrder: 2,
                    label: 'Portafoglio',
                    key: 'portfolio',
                    ref: portfolioRef,
                    icon: <FolderOpenOutlined/>,
                    component: <Portfolio {...baseProps} forwaredRef={portfolioRef}/>
                },
                {
                    uwOrder: 6,
                    portfolioOrder: 1,
                    label: 'Polizze ed emissioni',
                    key: 'policiesAndSubmissions',
                    ref: policiesAndIssuesRef,
                    icon: <SolutionOutlined/>,
                    component: isPortfolio && [States.Bound, States.Draft, States.Issued, States.InRinnovo, States.DaRinnovare, States.NonRinnovata].includes(selectedNegotiation.state) ?
                        <PortfolioPoliciesAndIssues {...baseProps} forwaredRef={policiesAndIssuesRef}/>
                        : <PoliciesAndIssues {...baseProps} forwaredRef={policiesAndIssuesRef}/>
                },
                {
                    uwOrder: 7,
                    portfolioOrder: 7,
                    label: 'Convenzioni',
                    key: 'agreements',
                    ref: agreementsRef,
                    icon: <ProjectOutlined/>,
                    component: <AgreementSection {...baseProps} forwaredRef={agreementsRef}/>
                },
            ]
            baseItems.sort((a, b) => {
                if (isPortfolio)
                    return a.portfolioOrder - b.portfolioOrder
                else
                    return a.uwOrder - b.uwOrder

            })

            setFormItems(baseItems)
        }
    }, [contractorDataForm, debounceInputChange, form, isAdditionalInsuredVisible, isFormDisabled, isPortfolio, negotiationId, onValuesChange, saveData, selectedNegotiation, setCalculateField])

    useEffect(() => {
        if (selectedNegotiation) {
            const formNegotiation = transformNegotiationToFormFields(selectedNegotiation)
            form.setFieldsValue(formNegotiation)
        }
    }, [form, selectedNegotiation])

    if (negotiationId && (isLoadingNegotiation || isUninitialized || !formItems.length)) {
        return <RiskAppSpinner/>
    }


    return (
        <main>
            {selectedNegotiation &&
                <>
                    {/*  added new Row for avoiding unnecessary row-gap when sidebar collapses */}
                    <Row>
                        <Col xs={0} xl={{span: isAsideVisible ? 0 : 24}}>
                            <Button
                                icon={<RightOutlined/>}
                                onClick={() => setIsAsideVisible(true)}
                                style={{
                                    position: 'fixed',
                                    left: '0.7rem',
                                    padding: '0.2rem',
                                    top: '50%',
                                    zIndex: '1',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{padding: '1.5rem 0 2rem 0'}}
                        justify={'space-evenly'}
                        gutter={[0, 16]}>
                        <Col xs={{span: 0, order: 0}} xl={{span: isAsideVisible ? 4 : 0, order: 1}}>
                            <NegotiationDetailMenu
                                menuItems={formItems}
                                hide={() => setIsAsideVisible(false)}
                                goToDocuments={() => goToDocuments(selectedNegotiation)}
                                isRore={selectedNegotiation.state === States.Rore}/>
                        </Col>
                        <Col xs={{span: 24, order: 2}} sm={{span: 23}} md={{span: 22}} lg={{span: 14, order: 1}}
                             xl={{span: isAsideVisible ? 12 : 14, order: 2}}>
                            <motion.div
                                initial={{opacity: 0, x: 0, y: 200}}
                                animate={{opacity: 1, x: 0, y: 0}}
                                exit={{opacity: 0, x: 0, y: -100}}
                                transition={{type: 'linear'}} // Set the transition to linear
                            >
                                <Row>
                                    <Col span={24}>
                                        <NegotiationAlerts negotiation={selectedNegotiation}/>
                                    </Col>
                                </Row>
                                <Form.Provider
                                    onFormChange={onFormChange}>
                                    <Row gutter={[0, 24]}>
                                        {formItems.map((formItem, idx) => (
                                                <Col
                                                    span={24}
                                                    key={idx}>
                                                    {formItem.component}
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </Form.Provider>
                            </motion.div>
                        </Col>
                        <Col xs={{span: 24, order: 1}} sm={{span: 23}} md={{span: 22}} lg={{span: 9, order: 2}}
                             xl={{span: isAsideVisible ? 7 : 9, order: 3}}
                             className={'negotiation-status'}>
                            {/* I pass saveData and not debounceInputChange because I need that the uw call is performed instantaneously (uw assignment) */}
                            <NegotiationDetailStatusManager
                                negotiation={selectedNegotiation}
                                setNegotiation={setSelectedNegotiation}
                                updateNegotiation={saveData}
                                form={form}
                                contractorDataForm={contractorDataForm}
                                setIsFormDisabled={setIsFormDisabled}
                                goToDocuments={() => goToDocuments(selectedNegotiation)}
                            />
                        </Col>
                    </Row>
                </>
            }
        </main>
    )


}