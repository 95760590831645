import {Link} from "react-router-dom";
import {notification} from "antd";

export default function AgreementChildren({negotiationUuid}: {negotiationUuid: string}) {

    return <Link
            to={`/negotiations/${negotiationUuid}`}
            onClick={() => notification.open({
                message: 'Attenzione, sei stato reindirizzato alla trattativa master.',
                type: 'warning',
                duration: 0
            })}
        >
            Vai alla trattativa master...
        </Link>
}