import {MenuProps, Space} from "antd";
import {Layout, Menu, Typography} from "antd";
import {ApartmentOutlined, FolderOutlined, HomeOutlined, UserOutlined, SettingOutlined, BankOutlined, FileOutlined, AuditOutlined} from '@ant-design/icons';
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from 'redux/features/authSlice'
import {selectUser} from "../redux/features/userSlice";
import {
    canSeeAuthorities,
    canSeeBankBranchesCharts,
    canSeeBrokerCharts,
    canSeeGeneralDocuments,
    canSeeSettings
} from "../utils/permission";
import Overlay from "./modals/Overlay";

const { Header, Content } = Layout
const { Title } = Typography


{/* todo adjust icons style with new style sheet*/
}

type MenuItem = Required<MenuProps>['items'][number];

export default function Index({ children }: { children: React.ReactNode }) {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState<string>('');
    const user = useSelector(selectUser)

    const menuItems: (MenuItem & { link?: string, onClick?: () => void })[] = useMemo(() => [
        {
            key: 'logo',
            icon: (
                <div style={{ backgroundColor: 'white', borderRadius: '99px', width: '32px', height: '32px', padding: '4px' }}>
                    <img alt={'Logo di Riskapp'} style={{ width: '100%', display: 'block' }} src={process.env.PUBLIC_URL + '/img/logo.png'} />
                </div>),
            label: <Title id='riskapp-logo' level={4}>RiskApp</Title>
        },
        {
            key: 'customers',
            onClick: () => {
                localStorage.removeItem('accessToken')
                window.location.href = (process.env.REACT_APP_URL || 'https://ayakodev.riskapp.it/webapp') + '/customers'
            },
            label: <Space align={'center'} direction={'vertical'}><HomeOutlined/>Customers</Space>,
        },
        {
            key: 'negotiations',
            link: '/negotiations',
            label: <Space align={'center'} direction={'vertical'}><FolderOutlined />Trattative</Space>
        },

    ], [])

    if (user && user.usertypes.length && canSeeAuthorities(user.usertypes.map(el=>el.code) )) {
        const authoritiesItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'authorities',
                link: '/authorities',
                label: <Space align={'center'} direction={'vertical'}><ApartmentOutlined />Authorities</Space>
            }
        menuItems.splice( 3 ,0, authoritiesItem)
    }

    if (user && user.usertypes.length && canSeeGeneralDocuments(user.usertypes.map(el=>el.code))) {
        const generalDocumentsItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'generaldocuments',
                link: '/generaldocuments',
                label: <Space align={'center'} direction={'vertical'}><FileOutlined />Documenti</Space>
            }
            menuItems.splice(4, 0, generalDocumentsItem)
    }

    if (user && user.usertypes.length && canSeeBrokerCharts(user.usertypes.map(el=>el.code))) {
        const settingsItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'brokers',
                link: '/brokers',
                label: <Space align={'center'} direction={'vertical'}><UserOutlined />Broker</Space>
            }
            menuItems.splice(5, 0, settingsItem)
    }

    if (user && user.usertypes.length && canSeeBankBranchesCharts(user.usertypes.map(el=>el.code))) {
        const settingsItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'branches',
                link: '/branches',
                label: <Space align={'center'} direction={'vertical'}><BankOutlined/>Filiali</Space>
            }
            menuItems.splice(6, 0, settingsItem)
    }

    menuItems.splice(7, 0, { key: 'divider', type: 'divider' })

    if (user && user.usertypes.length && canSeeSettings(user.usertypes.map(el=>el.code))) {
        const settingsItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'settings',
                link: '/settings',
                label: <Space align={'center'} direction={'vertical'}><AuditOutlined />Dati comuni</Space>
            }
        menuItems.splice(8, 0, settingsItem)
    }

    menuItems.splice(9, 0,
        {
            key: 'logout',
            label: <Space align={'center'} direction={'vertical'}><UserOutlined/>Logout</Space>,
        })

    useEffect(() => {
        const currentPageMenuItem = menuItems.find(item => item && item.key && location.pathname.includes(item.key.toString()))
        setSelectedItem(currentPageMenuItem?.key?.toString() || '')
    }, [location, menuItems])


    const onMenuItemClick: MenuProps['onClick'] = useCallback(
        (e: any) => {
            const link = e?.item?.props?.link
            const key = e?.key
            if (key === 'customers')
                return
            if (key === 'logout') {
                dispatch(logOut())
                return
            }

            if (link) {
                navigate(link)
            }
        },
        [dispatch, navigate],
    )

    return (
        <>
            {/* <Header style={{ padding: '0 1rem', position: 'sticky', top: 0, zIndex: 999 }} className={'header'}> */}
            <Overlay />
            <Header style={{ padding: '0 1rem' }} className={'header'}>
                <Menu
                    mode='horizontal'
                    //theme influence the selected/hover style
                    style={{ maxWidth: '100%', overflow: 'auto' }}
                    onClick={onMenuItemClick}
                    selectedKeys={[selectedItem]}
                    theme='dark'
                    items={menuItems}

                />
            </Header>
            <Content className={'basic-content-layout'}>
                {children}
            </Content>
        </>


    )
}