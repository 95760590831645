import {Button, message, Popconfirm} from "antd";
import {useDeleteAgreementRowMutation} from "../../../../redux/api/agreementRowApiSlice";
import {DeleteOutlined} from "@ant-design/icons";
import {useCallback} from "react";

export default function DeleteAgreementRow({agreementRowUuid, negotiationUuid, disabled}: {
    agreementRowUuid: string,
    negotiationUuid: string,
    disabled: boolean
}) {

    const [deleteAgreementRow, {isLoading}] = useDeleteAgreementRowMutation()
    const handleDelete = useCallback(async () => {
        try {
            await deleteAgreementRow({agreementRowUuid, negotiationUuid}).unwrap()
            message.success('Riga eliminata')
        } catch {
            message.error("Errore nella rimozione della riga")
        }
    }, [agreementRowUuid, deleteAgreementRow, negotiationUuid])

    return <Popconfirm
        title={'Vuoi eliminare la riga corrente?'}
        onConfirm={handleDelete}
    >
        <Button
            disabled={disabled}
            loading={isLoading}
            style={{color: 'red'}}
            icon={<DeleteOutlined/>}
            type='text'/>
    </Popconfirm>
}