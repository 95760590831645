import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';

import {
    Col,
    Row,
    Card,
    Form,
    Button,
    Select,
    Divider,
    Tooltip,
    message,
    Modal, Space, Popconfirm,
    FormInstance
} from 'antd';

import {Typography} from 'antd';

import debounce from 'lodash.debounce'

import {CommonFormComponentProps} from "types/negotiations/components";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";

import {PlusOutlined, DeleteOutlined, CheckCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {ReinsurerModel} from "types/reinsurer";
import {
    useAddReinsurerToNegotiationMutation,
    useCreateReinsurerMutation,
    useLazyGetNegotiationReinsurersQuery,
    useRemoveReinsurerFromNegotiationMutation,
    useUpdateReinsurerInNegotiationMutation
} from "redux/api/reinsurersApiSlice";
import {
    NegotiationFormModel,
    NegotiationModel,
    NegotiationReinsurerFormModel, NegotiationReinsurerFormModelPut,
    NegotiationReinsurerModel,
} from "types/negotiations";
import _, {cloneDeep, isEqual, isNil} from 'lodash'
import {AntCurrencyFormatInput} from "components/inputNumber";
import {normalizePercTo1, normalizePercTo100} from "utils/form/dataParser";
import {ReinsurerBrokerSelect, ReinsurerSelect} from "../selects";
import TextArea from "antd/es/input/TextArea";
import {percentage} from "../../../utils/formatters";
import {ReinsuranceType} from "../../../types/reinsuranceType";

const {Title} = Typography
const {Option} = Select

export default function Reinsurance({
                                        forwaredRef,
                                        formInstance,
                                        negotiation,
                                        onFormValuesChange,
                                        setCalculateField,
                                        disabled,
                                        saveData
                                    }: CommonFormComponentProps) {

    const {reinsuranceTypes, ppw_days: ppwDays} = useSelector(selectOptions)
    const watchReinsuranceType = Form.useWatch('reinsurance_type', formInstance)
    const watchIsaSharePerc = Form.useWatch('isa_share_perc', formInstance)

    return (
        <Card ref={forwaredRef} id="reinsurance" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Row>
                <Col>
                    <Title level={3}>
                        <CheckCircleOutlined/>
                    </Title>
                </Col>
                <Col style={{marginLeft: '12px'}}>
                    <Title level={3}>Riassicurazione</Title>
                </Col>
            </Row>
            <Form
                form={formInstance}
                disabled={disabled}
                name='reinsurance'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >

                <Form.Item style={{marginTop: '24px'}} label="Riassicurazione" name={'reinsurance_type'}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un tipo di riassicurazione"}
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {reinsuranceTypes.map(type => <Option key={type.value} value={type.value}>{type.text}</Option>)}
                    </Select>
                </Form.Item>
                {watchReinsuranceType === ReinsuranceType.FACOLTATIVO && (
                    <ReinsurerBrokerSelect
                        fieldName="reinsurer_broker"
                        label='Broker riassicurativo'
                        disabled={disabled}
                        onCreation={(value: string) => saveData({reinsurer_broker: value})}
                    />)}
                <Row align='bottom' justify='space-between'>
                    <Col xs={24} md={11}>
                        <Form.Item
                            label={
                                <Tooltip
                                    title={'Provvigioni diretto + overrider ISA'}>
                                    <Space>
                                        % Fee totali
                                        <InfoCircleOutlined/>
                                    </Space>
                                </Tooltip>
                            }
                            name='reinsurer_broker_fee_perc'
                            hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled}
                                                    suffix={' %'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            label={
                                <Tooltip
                                    title={'Percentuale riassicurata della quota di ISA'}>
                                    <Space>
                                        % Riassicurazione
                                        <InfoCircleOutlined/>
                                    </Space>
                                </Tooltip>
                            }
                            name='reinsured_share_perc'
                            hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                            <AntCurrencyFormatInput
                                hasPlaceholder={!disabled}
                                suffix={' %'}/>
                        </Form.Item>
                    </Col>
                </Row>

                {watchReinsuranceType === ReinsuranceType.FACOLTATIVO && (
                    <ReinsurersAndFeesManager
                        formInstance={formInstance}
                        negotiation={negotiation}
                        isaSharePerc={watchIsaSharePerc || 0}/>)
                }

                <Row align='bottom' justify='space-between'>
                    <Col xs={24}>
                        <Tooltip placement='top'
                                 title={formInstance.getFieldValue('is_calculated')?.isa_fee ? 'Premio imponibile quota ISA * %Riassicurazione * (Fee totali - Provvigioni totali Diretto)' : null}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.isa_fee !== curValues.is_calculated?.isa_fee}
                                style={{margin: 0, padding: 0}} hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                                {() => <Form.Item label="Importo Fees ISA" name='isa_fee'>
                                    <AntCurrencyFormatInput
                                        disabled={formInstance.getFieldValue('is_calculated')?.isa_fee}
                                        hasPlaceholder={!disabled}
                                        calculate={() => setCalculateField('isa_fee')}
                                    />
                                </Form.Item>}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col xs={24}>
                        <Tooltip placement='top'
                                 title={formInstance.getFieldValue('is_calculated')?.saved_premium ? 'Premio imponibile quota ISA * (1 - Provvigioni totali Diretto) * (1 - %Riassicurazione)' : null}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.saved_premium !== curValues.is_calculated?.saved_premium}
                                style={{margin: 0, padding: 0}} hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                                {() => <Form.Item label="Premio conservato ISA" name='saved_premium'>
                                    <AntCurrencyFormatInput
                                        disabled={formInstance.getFieldValue('is_calculated')?.saved_premium}
                                        hasPlaceholder={!disabled}
                                        calculate={() => setCalculateField('saved_premium')}
                                    />
                                </Form.Item>}
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col xs={24}>
                        <Tooltip placement='top'
                                 title={formInstance.getFieldValue('is_calculated')?.net_premium_to_reinsurers ? 'Premio imponibile quota ISA * (1 - % Fee totali) * % Riassicurazione' : null}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.net_premium_to_reinsurers !== curValues.is_calculated?.net_premium_to_reinsurers}
                                style={{margin: 0, padding: 0}} hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                                {() => <Form.Item label="Premio netto ceduto ai riassicuratori"
                                                  name='net_premium_to_reinsurers'>
                                    <AntCurrencyFormatInput
                                        disabled={formInstance.getFieldValue('is_calculated')?.net_premium_to_reinsurers}
                                        hasPlaceholder={!disabled}
                                        calculate={() => setCalculateField('net_premium_to_reinsurers')}
                                    />
                                </Form.Item>}
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col xs={24}>
                        <Form.Item label="Nota ai fini riassicurativi" name='reinsurance_notes'
                                   hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                            <TextArea autoSize={{minRows: 3}}
                                      placeholder={disabled ? "" : "Nota ai fini assicurativi"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Premium Payment Warranty (PPW)" name='ppw_days'
                                   hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO)}>
                            <Select
                                virtual={false}
                                showSearch
                                placeholder={disabled ? "" : "Seleziona i giorni di preavviso"}
                            >
                                {ppwDays.map(ppw => <Option key={ppw.value} value={ppw.value}>{ppw.text}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Attachment point" name='attachment_point'
                                   hidden={!(watchReinsuranceType === ReinsuranceType.FACOLTATIVO || negotiation.policy_product_type === 'E')}>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>

    )
}


export function ReinsurersAndFeesManager({
                                             negotiation,
                                             isaSharePerc,
                                             formInstance
                                         }: { negotiation: NegotiationModel, isaSharePerc: number, formInstance: FormInstance<NegotiationFormModel> }) {
    const [negotiationReinsurersAndFees, setNegotiationReinsurersAndFees] = useState<NegotiationReinsurerModel[]>([])
    const [isCreateReinsurerOpen, setIsCreateReinsurerOpen] = useState<boolean>(false)

    const [createReinsurerRequest, {isLoading: isCreateReinsurerLoading}] = useCreateReinsurerMutation()
    const [getReinsurersAndFeesRequest, {isLoading: isGetReinsurersLoading}] = useLazyGetNegotiationReinsurersQuery()
    const [addReinsurerRequest, {isLoading: isAddReinsurerLoading}] = useAddReinsurerToNegotiationMutation()
    const [removeReinsurerRequest, {isLoading: isRemoveReinsurerLoading}] = useRemoveReinsurerFromNegotiationMutation()
    const [updateReinsurerRequest, {isLoading: isUpdateReinsurerLoading}] = useUpdateReinsurerInNegotiationMutation()

    const [notAvailableReinsurers, setNotAvailableReinsurers] = useState<string[]>([])

    const [totalFee, setTotalFee] = useState<number>(0)
    const buttonText = 'Aggiungi riassicuratore'

    useEffect(() => {
        setNotAvailableReinsurers(negotiationReinsurersAndFees.map(el => el.reinsurer.uuid))
    }, [negotiationReinsurersAndFees])

    const fetchNegotiationReinsurersAndFees = useCallback(async () => {
        try {
            const reinsurersAndFees = await getReinsurersAndFeesRequest({negotiation_id: negotiation.uuid}).unwrap()

            //normalize percs from 0-1 to 0-100
            setNegotiationReinsurersAndFees(reinsurersAndFees.map(randFee => ({
                ...randFee,
                fee: +normalizePercTo100(randFee.fee || 0).toFixed(4)
            })))
        } catch (e: any) {
            console.error('fetchNegotiationReinsurersAndFees', e)
            message.error('Impossibile scaricare i riassicuratori di questa trattativa')

        }
    }, [getReinsurersAndFeesRequest, negotiation.uuid])


    useEffect(() => {
        fetchNegotiationReinsurersAndFees()
    }, [fetchNegotiationReinsurersAndFees])

    useEffect(() => {
        setTotalFee(negotiationReinsurersAndFees.reduce((previous, current) => previous + current.fee, 0))
    }, [negotiationReinsurersAndFees])


    async function createReinsurer(name: string) {
        try {
            const newReinsurer = await createReinsurerRequest({name}).unwrap()

            return newReinsurer
        } catch (e: any) {
            console.error('createReinsurer', e)
            message.error('Impossibile creare il riassicuratore')
            return undefined
        }
    }


    async function addReinsurerToNegotiation(reinsurer: string, normalizedFee: number) {
        try {
            const newReinsurer = await addReinsurerRequest({
                negotiation_id: negotiation.uuid,
                data: {reinsurer, fee: normalizedFee}
            }).unwrap()
            setNegotiationReinsurersAndFees([
                ...negotiationReinsurersAndFees,
                {...newReinsurer, fee: +normalizePercTo100(newReinsurer.fee || 0).toFixed(4)}
            ])
            message.success('Riassicuratore aggiunto')
        } catch (e: any) {
            console.error('addReinsurerToNegotiation', e)
            message.error('Impossibile aggiungere il riassicuratore')
        }
    }

    async function removeReinsurerFromNegotiation(negotiationReinsurerId: string) {
        try {
            await removeReinsurerRequest({
                negotiation_id: negotiation.uuid,
                reinsurer_id: negotiationReinsurerId
            }).unwrap()
            setNegotiationReinsurersAndFees(negotiationReinsurersAndFees.filter(randFee => randFee.uuid !== negotiationReinsurerId))
            message.success('Riassicuratore eliminato')
        } catch (e: any) {
            console.error('removeReinsurerToNegotiation', e)
            message.error('Impossibile rimuovere il riassicuratore')
        }
    }

    async function updateReinsurerInNegotiation(negotiationReinsurerId: string, data: NegotiationReinsurerFormModelPut) {
        //reinsurers are always updated even if the total fee is > 100
        updateReinsurerRequest({
            negotiation_id: negotiation.uuid,
            reinsurer_id: negotiationReinsurerId,
            data
        }).unwrap().then((updatedReinsurer) => {
            message.success('Riassicuratore aggiornato')
            fetchNegotiationReinsurersAndFees()
        }).catch((error) => {
            message.error('Impossibile aggiornare il riassicuratore')
            const displayData = cloneDeep(negotiationReinsurersAndFees)
            const index = displayData.findIndex(itm => itm.reinsurer.uuid === data.reinsurer)
            displayData[index] = {...displayData[index], fee: +normalizePercTo1(data.fee || 0).toFixed(4)}
            setNegotiationReinsurersAndFees(displayData)
        })
    }


    return (
        <>
            <Divider/>
            <CreateReinsurerModal isOpen={isCreateReinsurerOpen} onClose={() => setIsCreateReinsurerOpen(false)}
                                  notAvailableReinsurers={notAvailableReinsurers}
                                  addReinsurer={addReinsurerToNegotiation} createReinsurer={createReinsurer}
                                  isCreateReinsurerLoading={isAddReinsurerLoading} totalFee={totalFee}
                                  isaSharePerc={typeof negotiation.isa_share_perc === 'number' ? normalizePercTo100(negotiation.isa_share_perc) : null}/>
            <Row align='middle' justify='space-between' style={{marginBottom: '24px'}}>
                <Col>
                    <Title level={5} style={{margin: 0}}>Riassicuratori</Title>
                </Col>
                <Col>
                    <Button
                        disabled={typeof negotiation.isa_share_perc === 'number' ? totalFee >= normalizePercTo100(negotiation.isa_share_perc) : totalFee >= 100}
                        icon={<PlusOutlined/>}
                        onClick={() => setIsCreateReinsurerOpen(true)}>{buttonText}</Button>
                </Col>
            </Row>
            {totalFee >= isaSharePerc &&
                <p style={{color: 'red', transition: 'ease-in'}}> La somma delle percentuali riassicuratore non possono
                  essere maggiori o uguali della quota ISA ({isaSharePerc}%)</p>}
            {negotiationReinsurersAndFees.length === 0 &&
                <p style={{fontStyle: 'italic'}}>Nessun riassicuratore presente. Per aggiungerlo cliccare sul pulsante
                  '{buttonText}'</p>}
            {negotiationReinsurersAndFees.map((reinsurerAndFees, idx) => (
                    <ReinsurerAndFeesItem key={idx} idx={idx}
                                          notAvailableReinsurers={notAvailableReinsurers}
                                          reinsurerAndFees={reinsurerAndFees}
                                          setTotalFee={setTotalFee}
                                          updateReinsurer={(data: NegotiationReinsurerFormModelPut) => updateReinsurerInNegotiation(reinsurerAndFees.uuid, data)}
                                          createReinsurer={createReinsurer}
                                          isCreateReinsurerLoading={isCreateReinsurerLoading}
                                          removeReinsurer={() => removeReinsurerFromNegotiation(reinsurerAndFees.uuid)}
                                          isRemoveReinsurerLoading={isRemoveReinsurerLoading}
                                          hasErrorOccourred={totalFee > 100}
                                          premium={formInstance.getFieldValue('quoted_premium')}
                                          reinsurerBrokerFeePerc={formInstance.getFieldValue('reinsurer_broker_fee_perc')}
                    />
                )
            )}
            <Divider/>
        </>
    )
}

type CreateReinsurerModalProps = {
    isOpen: boolean,
    onClose: () => void,
    notAvailableReinsurers: string[],
    addReinsurer: (reinsurer: string, normalizedFee: number) => void,
    createReinsurer: (name: string) => Promise<ReinsurerModel | undefined>,
    isCreateReinsurerLoading: boolean,
    totalFee: number,
    isaSharePerc: number | null
}

export function CreateReinsurerModal({
                                         isOpen,
                                         onClose,
                                         notAvailableReinsurers,
                                         addReinsurer,
                                         createReinsurer,
                                         isCreateReinsurerLoading,
                                         totalFee,
                                         isaSharePerc
                                     }: CreateReinsurerModalProps) {

    const [form] = Form.useForm<{ reinsurer: string, fee: number }>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);


    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.reinsurer) && (typeof isaSharePerc === 'number' ? (fields.fee + totalFee) <= isaSharePerc : (fields.fee + totalFee) <= 100)
        setDisabledSubmit(hasErrors || !allFieldsOk);
    }

    async function onFinish(values: { reinsurer: string, fee: number }) {
        addReinsurer(values.reinsurer, normalizePercTo1(values.fee))
        form.resetFields()
        setDisabledSubmit(true)
        onClose()
    }

    return (


        <Modal
            title={'Aggiungi riassicuratore'}
            closable={false}
            centered
            open={isOpen}
            onOk={() => {
                form.resetFields()
                onClose()
            }}
            onCancel={() => {
                form.resetFields()
                onClose()
            }}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary'
                                onClick={() => onFinish(form.getFieldsValue())}>
                            Aggiungi
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form form={form} name="addNegotiationReinsurer" onValuesChange={onValuesChange} onFinish={onFinish}
                  style={{width: '100%'}} requiredMark={false} layout='vertical'>
                <Row align='middle' justify='space-between'>
                    <Col style={{width: '100%'}}>
                        <ReinsurerSelect fieldName="reinsurer"
                                         label='Riassicuratore'
                                         disabled={false}
                                         onCreation={(value: string) => form.setFieldsValue({reinsurer: value})}
                                         excludedReinsurers={notAvailableReinsurers}
                        />
                    </Col>
                    <Col style={{width: '100%'}}>
                        <Form.Item name='fee' label='Percentuale Riassicuratore' required initialValue={0}
                                   help={typeof (form.getFieldValue('fee') === 'number' &&
                                       typeof isaSharePerc === 'number' &&
                                       form.getFieldValue('fee') + totalFee >= isaSharePerc) ?
                                       <span style={{color: 'orange'}}>La somma delle percentuali riassicuratore non possono essere maggiori o uguali della quota ISA {percentage(normalizePercTo1(isaSharePerc || 0))}.</span>
                                       : typeof form.getFieldValue('fee') === 'number' && form.getFieldValue('fee') + totalFee >= 100 ?
                                           <span style={{color: 'orange'}}>La somma delle percentuali riassicuratore non possono superare il 100%.</span>
                                           : null
                                   }
                                   validateStatus={form.getFieldValue('fee') === undefined || isaSharePerc === null || ((+form.getFieldValue('fee') + totalFee) < isaSharePerc) || (form.getFieldValue('fee') + totalFee < 100) ? 'success' : 'error'}>
                            <AntCurrencyFormatInput suffix={' %'} max={(isaSharePerc || 100) - totalFee}
                                                    decimalScale={4}/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    )


}

type ReinsurerAndFeesItemProp = {
    idx: number,
    notAvailableReinsurers: string[],
    reinsurerAndFees: NegotiationReinsurerModel,
    updateReinsurer: (data: NegotiationReinsurerFormModelPut) => void,
    createReinsurer: (name: string) => Promise<ReinsurerModel | undefined>,
    isCreateReinsurerLoading: boolean,
    removeReinsurer: () => void,
    isRemoveReinsurerLoading: boolean,
    hasErrorOccourred: boolean,
    premium: number | null,
    reinsurerBrokerFeePerc: number | null,
    setTotalFee: Dispatch<SetStateAction<number>>
}

const debounceReinsurerChange = debounce((updateReinsurer, data) => updateReinsurer(data), 1500);

export function ReinsurerAndFeesItem({
                                         idx,
                                         notAvailableReinsurers,
                                         reinsurerAndFees,
                                         updateReinsurer,
                                         createReinsurer,
                                         isCreateReinsurerLoading,
                                         removeReinsurer,
                                         isRemoveReinsurerLoading,
                                         hasErrorOccourred,
                                         premium,
                                         reinsurerBrokerFeePerc,
                                         setTotalFee
                                     }: ReinsurerAndFeesItemProp) {
    const [form] = Form.useForm<NegotiationReinsurerFormModel>()

    useEffect(() => {
        const formValues = form.getFieldsValue()
        if (formValues.reinsurer !== reinsurerAndFees.reinsurer.uuid) {
            form.setFieldsValue({reinsurer: reinsurerAndFees.reinsurer.uuid})
        }
        if (formValues.fee !== reinsurerAndFees.fee) {
            form.setFieldsValue({fee: reinsurerAndFees.fee})
        }
    }, [reinsurerAndFees, form])


    // setTotalFee(prevState => prevState - reinsurerAndFees.fee + (values.fee || 0))

    function onValuesChange(changedValues: Partial<NegotiationReinsurerFormModel>, values: NegotiationReinsurerFormModel) {
        //Due to AntCurrencyInputIssue, otherwise trigger at every form.SetField (despite it shouldn't by design)
        if ((values.fee !== reinsurerAndFees.fee) || (values.reinsurer !== reinsurerAndFees.reinsurer.uuid)) {
            setTotalFee(prevState => prevState - reinsurerAndFees.fee + (values.fee || 0))
            debounceReinsurerChange(updateReinsurer, {
                ...values,
                fee: values.fee === undefined ? null : +normalizePercTo1(values.fee).toFixed(6)
            })
        }
    }

    return (
        <Form
            form={form}
            name={`reinsuranceReinsurerAndFees${idx}`}
            layout="vertical"
            requiredMark={false}
            onValuesChange={onValuesChange}
        >
            <Row align='bottom' justify='space-between'>
                <Col xs={{span: 24, flex: 1}} md={12}>
                    <ReinsurerSelect fieldName="reinsurer"
                                     label={`Riassicuratore ${idx + 1}`}
                                     disabled={false}
                                     onCreation={(value: string) => {
                                         form.setFieldsValue({reinsurer: value})
                                     }}
                                     excludedReinsurers={notAvailableReinsurers.filter(el => el !== reinsurerAndFees.reinsurer.uuid)}
                                     {...!!reinsurerAndFees?.reinsurer && {selectedReinsurer: reinsurerAndFees.reinsurer}}
                    />
                </Col>
                <Col xs={{span: 24}} md={5}>
                    <Form.Item
                        label={
                            <Tooltip
                                title={'Percentuale del riassicuratore calcolata sul 100% del rischio'}>
                                <Space>
                                    Percentuale
                                    <InfoCircleOutlined/>
                                </Space>
                            </Tooltip>
                        }
                        name={'fee'}
                        required
                        validateStatus={hasErrorOccourred === true ? 'error' : 'success'}
                    >
                        <AntCurrencyFormatInput
                            suffix={' %'}
                            decimalScale={4}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} md={5}>
                    <Tooltip placement='top' title='Premio netto ceduto (not saved): premio imponibile 100% * (1 - % Fee totali) * % Riassicuratore'>
                        <Form.Item label={'Premio netto ceduto'}
                                   shouldUpdate={(prevValues, nextValues) => prevValues.fee !== nextValues.fee}>
                            {() => <AntCurrencyFormatInput
                                disabled
                                value={(premium || 0) * (1 - normalizePercTo1(reinsurerBrokerFeePerc || 0)) * normalizePercTo1(form.getFieldValue('fee') || 0)}
                            />}
                        </Form.Item>
                    </Tooltip>
                </Col>
                <Col xs={{span: 24}} md={1}>
                    <Popconfirm
                        title="Eliminare il riassicuratore?"
                        okText="Si"
                        cancelText="No"
                        onConfirm={removeReinsurer}
                    >
                        <Form.Item>
                        <Button icon={<DeleteOutlined/>} disabled={isRemoveReinsurerLoading} type='ghost'/>
                    </Form.Item>
                    </Popconfirm>
                </Col>
            </Row>
        </Form>
    )
}