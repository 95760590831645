import {apiSlice} from "redux/api/baseApiSlice";
import {GenericPaginatedApi} from "../../types";

export type GetAgreementRow = {
    uuid: string,
    contractor: { uuid: string, name: string },
    fund: { uuid: string, name: string } | null,
    quoted_premium: number,
    negotiation_agreement: string | null,
}

export type PostPatchAgreementRow = {
    contractor: string,
    fund?: string,
    quoted_premium: number,
}

export const AgreementRowApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => {
        return ({
            getAgreementRows: builder.query<GenericPaginatedApi<GetAgreementRow> & { premiums: number }, {
                negotiationUuid: string,
                page?: number,
                page_size?: number
            }>({
                query: ({negotiationUuid, ...params}) => {
                    return {
                        url: `/negotiation/negotiations/${negotiationUuid}/agreementrows/`,
                        params
                    };
                },
                providesTags: (res, error, arg) => res ? [
                        ...res.results.flatMap(el => [
                            {type: 'AgreementRows' as const, id: el.uuid},
                            ...el.negotiation_agreement ? [{
                                type: 'AgreementRows' as const,
                                id: el.negotiation_agreement
                            }] : []]),
                        {type: 'AgreementRows', id: 'LIST'},
                        {type: 'AgreementRows' as const, id: arg.negotiationUuid}
                    ] :
                    [
                        {type: 'AgreementRows', id: 'LIST'},
                        {type: 'AgreementRows' as const, id: arg.negotiationUuid}
                    ],
            }),
            createAgreementRow: builder.mutation<GetAgreementRow, PostPatchAgreementRow & { negotiationUuid: string }>({
                query: ({negotiationUuid, ...body}) => {
                    return {
                        url: `/negotiation/negotiations/${negotiationUuid}/agreementrows/`,
                        method: 'POST',
                        body
                    };
                },
                invalidatesTags: [{type: 'AgreementRows', id: 'LIST'}, 'CoverageConfirmation'],
            }),
            patchAgreementRow: builder.mutation<GetAgreementRow, PostPatchAgreementRow & {
                negotiationUuid: string,
                agreementRowUuid: string
            }>({
                query: ({negotiationUuid, agreementRowUuid, ...body}) => {
                    return {
                        url: `/negotiation/negotiations/${negotiationUuid}/agreementrows/${agreementRowUuid}/`,
                        method: 'PATCH',
                        body
                    };
                },
                invalidatesTags: (res, error, arg) => [
                    {type: 'AgreementRows', id: 'LIST'},
                    {type: 'AgreementRows' as const, id: arg.agreementRowUuid},
                    'CoverageConfirmation'
                ],
            }),
            deleteAgreementRow: builder.mutation<void, { negotiationUuid: string, agreementRowUuid: string }>({
                query: ({negotiationUuid, agreementRowUuid}) => {
                    return {
                        url: `/negotiation/negotiations/${negotiationUuid}/agreementrows/${agreementRowUuid}/`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: [{type: 'AgreementRows', id: 'LIST'}, 'CoverageConfirmation'],
            }),
            createRelateNegotiations: builder.mutation<void, { negotiationUuid: string }>({
                query: ({negotiationUuid}) => {
                    return {
                        url: `/negotiation/negotiations/${negotiationUuid}/agreementrows/create_relate_negotiations/`,
                        method: 'GET',
                    };
                },
                invalidatesTags: [{type: 'AgreementRows', id: 'LIST'}],
            }),
        });
    }
})

export const {
    useGetAgreementRowsQuery,
    useCreateAgreementRowMutation,
    usePatchAgreementRowMutation,
    useDeleteAgreementRowMutation,
    useCreateRelateNegotiationsMutation,
} = AgreementRowApiSlice