import {Survey as SurveyReact} from "survey-react-ui";
import {
    Question,
    QuestionCheckboxModel,
    QuestionCommentModel,
    QuestionDropdownModel,
    QuestionRadiogroupModel,
    QuestionTextModel
} from "survey-react";
import {ChoicesRestfull, CustomWidgetCollection, Model} from "survey-core";
import {useParams} from "react-router-dom";
import {useGetNegotiationDetailQuery,} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Col, message, Row, Skeleton, Switch} from "antd";
import {useEffect, useState} from "react";
import _, {isEqual} from "lodash";
import "survey-core/defaultV2.min.css";
import {useSurveyContext} from "../../context/SurveyContext";
import {
    CustomCheckboxGroup,
    CustomComment,
    CustomDateInput,
    CustomInput,
    CustomPercentageInput,
    CustomRadioGroup,
    CustomSelectInput,
    EurCurrencyCustomWidgetInput,
    NumericCustomWidgetInput
} from "../negotiationForm/sidebars/status/modals/checklist";
import 'survey-core/modern.min.css';
import survey_theme from "../../assets/survey_theme.json";
import SurveyButtons from "./SurveyButtons";
import "survey-core/i18n/italian";
import {useSurveyPage} from "../../context/SurveyPageContext";
import {useUploadNegotiationFiles} from "../../hooks/useUploadNegotiationFiles";
import "../surveyjs/FilePreviewComponent";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {canSeeAllCoverageConfirmationQuestions} from "../../utils/permission";
import {
    useGetNegotiationCoverageConfirmationDataQuery,
    useGetNegotiationCoverageConfirmationSchemaQuery,
    usePatchCoverageConfirmationMutation,
    useStartPatchFromRoreMutation
} from "../../redux/api/coverageConfirmationApiSlice";

const QUOTATION_DIRECTORY = "4.1"
const CoverageConfirmationSurvey = ({disabled}: { disabled: boolean }) => {

  const {id} = useParams();
  const {pageType} = useSurveyPage()
  const {data: negotiation, isLoading: isNegotiationLoading} = useGetNegotiationDetailQuery(id ?? skipToken);
  const user = useSelector(selectUser)
  const canSeeInvisibleElements = !!(user && user.usertypes.length && canSeeAllCoverageConfirmationQuestions(user.usertypes.map(el => el.code)))
  const [showInvisibleElements, setShowInvisibleElements] = useState(false)

  const {
    data: coverageConfirmationSchema,
  } = useGetNegotiationCoverageConfirmationSchemaQuery(negotiation?.coverageconfirmation ?? skipToken);
  const {
    data: coverageConfirmationData,
    isLoading: isCoverageConfirmationDataLoading,
  } = useGetNegotiationCoverageConfirmationDataQuery((coverageConfirmationSchema && negotiation?.coverageconfirmation) ?? skipToken);

  const [
    patchCoverageConfirmation,
  ] = usePatchCoverageConfirmationMutation();
  const {upload} = useUploadNegotiationFiles({
    negotiationUuid: negotiation?.uuid,
    coverageConfirmationUuid: negotiation?.coverageconfirmation || undefined,
  })
  const [startPatchFromRore] = useStartPatchFromRoreMutation();

  const [survey, setSurvey] = useSurveyContext();

  useEffect(() => {
    // add custom widget

    // expression custom widget
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'expressionEurCurrency',
      title: 'expression eur currency',
      render: (question: Question) => <EurCurrencyCustomWidgetInput question={question} isReadOnly/>,
      isFit: (question: Question) => {
        return question.getType() === 'expression' && question.displayStyle === 'currency'
      },
    })
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'expressionNumeric',
      title: 'expression numeric',
      render: (question: Question) => <NumericCustomWidgetInput question={question} isReadOnly/>,
      isFit: (question: Question) => {
        return question.getType() === 'expression' && question.displayStyle === 'decimal'
      },
    })
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'expressionPercentage',
      title: 'expression percentage',
      render: (question: Question) => <CustomPercentageInput question={question} isReadOnly/>,
      isFit: (question: Question) => {
        return question.getType() === 'expression' && question.displayStyle === 'percentage'
      },
    })
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'expressionDate',
      title: 'expression date',
      render: (question: Question) => <CustomDateInput question={question} isReadOnly/>,
      isFit: (question: Question) => {
        return question.getType() === 'expression' && question.displayStyle === 'date'
      },
    })

    // custom widget according to question name DEPRECATED
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customnumericvalue',
      title: 'custom Numeric value',
      render: (question: Question) => <EurCurrencyCustomWidgetInput question={question}/>,
      isFit: (question: Question) => {
        return question.name.charAt(0) === '$'
      },
    }, "customnumericvalue")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'custompercentage',
      title: 'custom Percentage',
      render: (question: Question) => <CustomPercentageInput question={question}/>,
      isFit: (question: Question) => question.name.charAt(0) === '%',
    }, "custompercentage")

    // generic custom widget
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customnumeric',
      title: 'custom Numeric',
      render: (question: Question) => <NumericCustomWidgetInput question={question}/>,
      isFit: (question: Question) => {
        // @ts-ignore
        return (question.getType() === 'text' && question.inputType === 'number')
      },
    }, "customnumeric")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customdropdown',
      title: 'custom Dropdown',
      render: (question: Question) => <CustomSelectInput question={question as QuestionDropdownModel}/>,
      isFit: (question: Question) => question.getType() === 'dropdown',
    }, "customdropdown")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customDate',
      title: 'custom date',
      render: (question: Question) => <CustomDateInput question={question as QuestionDropdownModel}/>,
      // @ts-ignore
      isFit: (question: Question) => question.inputType === 'date',
    }, "customDate")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customRadioGroup',
      title: 'custom radio group',
      render: (question: Question) => <CustomRadioGroup question={question as QuestionRadiogroupModel}/>,
      isFit: (question: Question) => question.getType() === 'radiogroup',
    }, "customRadioGroup")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customCheckboxGroup',
      title: 'custom checkbox group',
      render: (question: Question) => <CustomCheckboxGroup question={question as QuestionCheckboxModel}/>,
      isFit: (question: Question) => question.getType() === 'checkbox',
    }, "customCheckboxGroup")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customText',
      title: 'custom text',
      render: (question: Question) => <CustomInput question={question as QuestionTextModel}/>,
      isFit: (question: Question) => question.getType() === 'text',
    }, "customText")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customComment',
      title: 'custom comment',
      render: (question: Question) => <CustomComment question={question as QuestionCommentModel}/>,
      isFit: (question: Question) => question.getType() === 'comment',
    }, "customComment")

    ChoicesRestfull.onBeforeSendRequest = function (sender, options: { request: XMLHttpRequest }) {
      const authToken = localStorage.getItem("accessToken");
      options.request.setRequestHeader("Authorization", "JWT " + authToken);
    }


  }, []);

  // SURVEY UPDATE -> important: don't use setSurvey for updating the survey but directly the survey instance
  useEffect(() => {
    if (survey) {
      survey.mode = disabled ? 'display' : 'edit';
      survey.showInvisibleElements = showInvisibleElements
    }
  }, [disabled, showInvisibleElements, survey]);
  // SURVEY UPDATE -> important: don't use setSurvey for updating the survey but directly the survey instance
  useEffect(() => {
    // survey update due to external or async updates (ex. rore process)
    if (survey && coverageConfirmationData?.data && !isEqual(coverageConfirmationData?.data, survey.getAllValues())) {
      survey.data = coverageConfirmationData.data
    }
  }, [coverageConfirmationData?.data, survey]);


  useEffect(() => {
    // SURVEY CONFIG
    if (!survey && coverageConfirmationSchema?.schema && negotiation && negotiation.coverageconfirmation) {
      const composeUrl = (url: string) => `${process.env.REACT_APP_API_URL}${url}?uuid=${negotiation.uuid as string}`;
      let surveySchema = _.cloneDeep(coverageConfirmationSchema.schema);
      surveySchema
        // @ts-ignore
        .pages
        .forEach((page: any) =>
          page
            .elements
            .forEach((element: any) => {
              if (element.type === "paneldynamic") {
                element.templateElements.forEach((panelDynamicElement: any) => {
                  if (panelDynamicElement.type === 'dropdown' && panelDynamicElement.choicesByUrl?.url && !panelDynamicElement.choicesByUrl.url.includes("http")) {
                    panelDynamicElement.choicesByUrl.url = composeUrl(panelDynamicElement.choicesByUrl.url);
                  }
                })
              }
              if (element.type === 'dropdown' && element.choicesByUrl?.url && !element.choicesByUrl.url.includes("http")) {
                element.choicesByUrl.url = composeUrl(element.choicesByUrl.url);
              }
              if (element.type === "matrixdynamic") {
                element.columns.forEach((el: any) => {
                  if (el.choicesByUrl?.url && !el.choicesByUrl.url.includes("http"))
                    el.choicesByUrl.url = composeUrl(el.choicesByUrl.url);
                })
              }
              element.errorLocation = "bottom";
            }))

      const s = new Model(surveySchema);
      s.data = coverageConfirmationData?.data
      s.onValueChanged.add(_.debounce(async (sender) => {
        const results = sender.getAllValues();
        if (!_.isEmpty(results)) {
          try {
            await patchCoverageConfirmation({
              uuid: negotiation.coverageconfirmation as string,
              data: results
            }).unwrap();
          } catch (e) {
            message.error('Errore nel salvataggio')
            console.error(e);
          }
        }
      }, 1200),);
      s.onUploadFiles.add((_, options) => {
        const filesParams = options.files.map(el => ({
          ...el,
          dirType: QUOTATION_DIRECTORY,
          fileName: el.name,
          uid: el.name
        }))

        upload(
          filesParams,
          [options.files],
          QUOTATION_DIRECTORY,
          options.question.name
        )
          .then(fileUploadResponse => {
            if (negotiation && negotiation.coverageconfirmation) {
              startPatchFromRore({
                coverageConfirmationUuid: negotiation.coverageconfirmation,
                coverageConfirmationQuestionCode: options.question.name,
                documentUuid: fileUploadResponse[0].uuid,
                surveyData: {questionName: options.question.name, fileUploadResponse}
              }).unwrap()
            }
          })
          .catch(reason => {
            console.log(reason)
          })
      });
      s.mode = 'display';
      s.showNavigationButtons = "none";
      s.showCompletedPage = false;
      s.locale = 'it';

      s.getAllQuestions().forEach(question => {
        question.requiredErrorText = "Campo obbligatorio";
        if (question.getType() === "file") {
          question.storeDataAsText = false;
          question.showPreview = false;
        }
      })
      s.focusOnFirstError = true;
      s.focusFirstQuestionAutomatic = false;
      s.applyTheme({cssVariables: survey_theme.cssVariables, isPanelless: survey_theme.isPanelless});
      s.setCss(
        {
          question: {
            header: "ant-col ant-form-item-required",
            title: "ant-form ant-form-item-label question-title align-left",
            content: "ant-col ant-form-item-control",
            description: "ant-form-item-explain",
            hasError: "ant-form-item-explain-error",
          },
          text: {
            root: "ant-input",
          },
          comment: {
            root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
          },
          paneldynamic: {
            buttonAdd: 'ant-btn ant-btn-primary btn-hover with-margin',
            buttonRemove: 'ant-btn with-margin',
          },
          matrixdynamic: {
            columns: "with-margin",
            button: 'ant-btn',
            buttonAdd: 'ant-btn ant-btn-primary btn-hover with-margin',
          },
        }
      )
      setSurvey(s);
    }
  }, [coverageConfirmationData?.data, coverageConfirmationSchema?.schema, negotiation, patchCoverageConfirmation, setSurvey, startPatchFromRore, survey, upload]);

  return (
    <>
      <Skeleton loading={(isNegotiationLoading || isCoverageConfirmationDataLoading) && !!survey} active={true}>
        {survey && <>
          {pageType === 'surveyJs' && <>
            {canSeeInvisibleElements && <Row style={{margin: "2rem 0"}}>
              <Col>
                Rendi visibili tutte le domande: <Switch
                checked={showInvisibleElements}
                onChange={checked => setShowInvisibleElements(checked)}
              />
              </Col>
            </Row>}
            <SurveyReact model={survey}/>
          </>}
          <SurveyButtons/>
        </>}
      </Skeleton>
    </>
  );
};

export default CoverageConfirmationSurvey;