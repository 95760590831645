import React, {useEffect, useRef } from 'react';
import ReactDOM from "react-dom/client";
import {Alert, Button, ConfigProvider, Row} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useCheckConnection} from "./hooks/useCheckConnection";

import reportWebVitals from './reportWebVitals';
import Router from 'router'
// import { AuthenticationContextProvider } from 'context/authContext';
// import { NotificationContextProvider } from 'context/notificationContext';
import {BrowserRouter} from 'react-router-dom'
import './styles/index.less';
import {Provider} from 'react-redux';
import {store} from 'redux/store'
import AuthMiddleware from 'authMiddleware'
import itIT from 'antd/es/locale/it_IT';

const basename = process.env.REACT_APP_BASENAME || '';
const {ErrorBoundary} = Alert;

function ErrorDescription() {
  return <>
    <Row justify={"center"} style={{marginTop: "10%", marginBottom: "20px"}}><ExclamationCircleOutlined
      style={{fontSize: "30px", color: "lightblue"}}/></Row>
    <Row justify={"center"}>Qualcosa è andato storto, per favore ricarica la pagina.</Row>
    <Row justify={"center"}>Se il problema persiste contattaci all'indirizzo support@riskapp.it</Row>
    <Row justify={"center"} style={{marginTop: "10px"}}><Button onClick={() => window.location.reload()}
                                                                style={{borderRadius: "3px"}}>Ricarica
      pagina</Button></Row>
  </>
}

function Index() {

  const appRef = useRef<HTMLDivElement | null>(null);

  useCheckConnection();

  /*this useEffect hides the application whether if it contained in an iframe*/
  useEffect(() => {
    if (window && window.self !== window.top && appRef.current) {
      appRef.current.className = 'clickjackCountermeasure';
      if (window.top) {
        window.top.location = window.self.location;
      }
    }
  }, []);

  return (
    <div ref={appRef}>
      <ConfigProvider locale={itIT}>
        <Provider store={store}>
          <BrowserRouter basename={basename}>
            <AuthMiddleware>
              <ErrorBoundary description={<ErrorDescription/>} message={false}>
                <Router/>
              </ErrorBoundary>
            </AuthMiddleware>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Index/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
